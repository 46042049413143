import Button from 'components/atoms/Button';
import './style.scss';

export const pricingTiersData = [
  { name: "micro", credits: 75, events: 400000, price: 9, additional: 0.13, trial: true, monitors: 1 },
  { name: "mini", credits: 350, events: 2000000, price: 50, additional: 0.13, trial: true, monitors: 1, visible: false },
  { name: "small", credits: 900, events: 4000000, price: 99, additional: 0.12, trial: true, monitors: 4 },
  { name: "medium", credits: 2990, events: 40000000, price: 299, additional: 0.11, trial: false, monitors: 14 },
  { name: "large", credits: 6655, events: 500000000, price: 599, additional: 0.10, trial: false, monitors: 33 },
];

const PricingTierSection = (props) => {
  const { lang, section, tier } = props;
  const { type, title, value, unit, terms, main } = section;
  const tierName = tier?.name;
  const trial = tier?.trial;
  const apiHost = process.env.REACT_APP_API_BASE_URL;

  const buttonLabels_sv = {
    starttrial: "Starta testperiod",
    signup: "Skapa konto"
  }

  const buttonLabels_en = {
    starttrial: "1-month trial",
    signup: "Create account"
  }

  const buttonLabels = lang === "sv" ? buttonLabels_sv : buttonLabels_en;

  const buttonLabel = trial ? buttonLabels.starttrial : buttonLabels.signup;

  return (
    <div className={"PricingTierSection" + (main ? " PricingTierSection--main" : "") + (type ? " PricingTierSection--" + type : "")}>
      {title &&
        <h4 className="PricingTierSection__title">
          {title}
        </h4>
      }

      {value && unit &&
        <div className="PricingTierSection__cols">
          <span className="PricingTierSection__value">
            {value}
          </span>
          <span className="PricingTierSection__unit">
            {unit}
          </span>
        </div>
      }

      {type === "header" && tierName &&
        <h3 className="PricingTierSection__tierName">{tierName}</h3>
      }

      {type === "CTA" &&
        <>
          <Button
            type="htmllink"
            className="PricingTierSection__button"
            href={apiHost + "/api/v1/accounts/login"}
          >
            <span>
              {buttonLabel}
            </span>
          </Button>

          <div className="PricingTierSection__terms">
            {terms}
          </div>
        </>
      }
    </div>
  );
}

const PricingTier = (props) => {
  const { pricingTierSections, lang, tier } = props;

  return (
    <div className="PricingTier">
      {pricingTierSections.map((section, index) => {
        return (
          <PricingTierSection
            key={index}
            lang={lang}
            section={section}
            tier={tier}
          />
        );
      })}
    </div>
  );
}

function PricingTiers(props) {
  const { lang, title, features, intro, generateSections, theme } = props;
  // filter out the tiers that are not visible
  const tiers = (props.tiers ?? pricingTiersData).filter(tier => tier.visible !== false);
  const themeIsGray = theme === "gray";

  return (
    <div className={"PricingTiers" + (themeIsGray ? " PricingTiers--gray" : "") + (features ? " PricingTiers--hasFeatures" : "")}>
      <div className="PricingTiers__header">
        {title &&
          <h2 className="PricingTiers__title">{lang === "sv" ? "Priser" : "Pricing"}</h2>
        }
        {intro && <div className="PricingTiers__intro">{intro}</div>}
      </div>

      <div className="PricingTiers__tiers">
        {
          tiers.map((tier, index) => {
            const pricingTierSections = generateSections(tier);
            return (
              <PricingTier
                key={index}
                lang={lang}
                tier={tier}
                pricingTierSections={pricingTierSections}
                theme={theme}
              />
            );
          })
        }
      </div>

      {
        features &&
        <div className="PricingTiers__features">
          {features.title &&
            <h3 className="PricingTiers__featuresTitle">{features.title}</h3>
          }
          {
            features.data &&
            <ul className="PricingTiers__featuresList">
              {features.data.map((feature, index) => <li key={index} className="PricingTiers__featuresItem">{feature}</li>)}
            </ul>
          }
          {features.content &&
            <div className="PricingTiers__featuresContent">{features.content}</div>
          }
        </div>
      }
    </div>
  );
}

export default PricingTiers;
