import Footer from 'components/molecules/Footer';
import './style.scss';
import LegacyPageSidebar from 'components/organisms/LegacyPageSidebar';
import Sidebar from 'components/organisms/Sidebar';
import { useCheckAccess } from 'hooks/useCheckAccess';
import { useParams } from 'react-router-dom';
import Notification from 'components/molecules/Notification';
import { useEffect, useState } from 'react';
import LoadingDots from 'components/atoms/LoadingDots';
import ErrorWidget from 'components/molecules/ErrorWidget';
import PageSidebar from 'components/organisms/PageSidebar';
import { useAppData } from 'hooks/useAppData';
import useBodyClass from 'hooks/useBodyClass';

const notifications = {
  monitor: {
    message: "You need to set up billing before you can use GDPR monitoring",
    buttonHref: "billing",
    buttonText: "Billing"
  },
  sst: {
    message: "You need to set up billing before you start using server-side tracking",
    buttonHref: "billing",
    buttonText: "Billing"
  },
  warehouse: {
    message: "You need to set up billing before you can create a data warehouse",
    buttonHref: "billing",
    buttonText: "Billing"
  },
  datasources: {
    message: "You need to create a data warehouse to add data sources",
    buttonHref: "setup_warehouse",
    buttonText: "Data warehouse"
  },
  copilot: {
    message: "You need to add data sources to use Data copilot",
    buttonHref: "datasources",
    buttonText: "Data sources"
  },
  impact: {
    message: "You need to save metrics in Data copilot to use Impact analysis",
    buttonHref: "terminal",
    buttonText: "Data co-pilot"
  },
  datavis: {
    message: "You need to create a data warehouse before you can build dashboards",
    buttonHref: "setup_warehouse",
    buttonText: "Data warehouse"
  }
}

const DependencyNotification = (props) => {
  const { checkAccess, hasAccess, onDismissClick } = props;
  const { getPath } = useAppData();
  if (hasAccess || hasAccess === null) return null;
  if (!notifications[checkAccess] || !notifications[checkAccess].message) return null;
  const notification = notifications[checkAccess];

  return (
    <>
      <Notification
        message={notification.message}
        action={notification.buttonText}
        buttonType="link"
        buttonHref={getPath(notification.buttonHref)}
        hasDismiss
        onDismissClick={onDismissClick}
      />
    </>
  )
}

const MainMeta = (props) => {
  const { messageDismissed, checkAccess, hasAccess, children, onDismissClick } = props;

  return (
    <div className="MainMeta">
      {!messageDismissed && checkAccess && !hasAccess &&
        <DependencyNotification
          checkAccess={checkAccess}
          hasAccess={hasAccess}
          onDismissClick={onDismissClick}
        />
      }
      {children}
    </div>
  );
}

const MainDisabled = (props) => {
  if (props.disabled) {
    return <span className="Main__disabled">{props.children}</span>
  } else {
    return <span>{props.children}</span>
  }
}

function Main(props) {
  const params = useParams();
  const warehouseId = params.warehouse_id;
  const checkAccess = typeof props.checkAccess !== "undefined" ? props.checkAccess : false;
  const hasAccess = useCheckAccess(checkAccess, warehouseId);
  const [messageDismissed, setMessageDismissed] = useState(false);

  useBodyClass([
    (props.noSidebar ? "no-sidebar" : "has-sidebar"),
    (props.hasPageSidebar ? "has-page-sidebar" : "no-page-sidebar")
  ]);

  useEffect(() => {
    return () => {
      setMessageDismissed(false);
    }
  }, [checkAccess, warehouseId])

  let mainClass = "Main";
  if (props.noSidebar) {
    mainClass += " Main--withoutSidebar";
  } else {
    mainClass += " Main--withSidebar";
  }
  if (props.pageSidebar || props.hasPageSidebar) {
    mainClass += " Main--withPageSidebar";
  }

  if (["small", "medium", "large"].includes(props.size)) {
    mainClass += " Main--" + props.size + "Size";
  }

  if (props.fullScreen) {
    mainClass += " Main--fullscreen";
  }

  const disableAccess = !messageDismissed && checkAccess && !hasAccess;

  return (
    <>
      {!props.noSidebar && <Sidebar />}

      {hasAccess === null ?
        <main className="Main"><LoadingDots /></main>
        :
        <>
          {props.pageSidebar &&
            <MainDisabled disabled={disableAccess}>
              <LegacyPageSidebar children={props.pageSidebar} />
            </MainDisabled>
          }
          {props.hasPageSidebar &&
            <MainDisabled disabled={disableAccess}>
              <PageSidebar />
            </MainDisabled>
          }

          <main className={mainClass}>
            <div className="Main__meta">
              <MainMeta
                checkAccess={checkAccess}
                hasAccess={hasAccess}
                messageDismissed={messageDismissed}
                onDismissClick={() => setMessageDismissed(true)}
              />
            </div>

            <MainDisabled
              disabled={disableAccess}
            >
              {props.children}
            </MainDisabled>
            <Footer />
          </main>
        </>
      }

      {!props.isError && <ErrorWidget />}

    </>
  );
}

export default Main;
