import './style.scss';

function Input(props) {
  const {
    className = "",
    type = "text",
    name,
    id,
    placeholder,
    onChange,
    value,
    disabled,
    inputAttributes,
    label,
    description,
    checked,
    onFocus,
    onBlur,
  } = props;
  return (
    <>
      <input
        className={"Input " + className}
        key={id}
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={disabled}
        checked={checked}
        onFocus={onFocus}
        onBlur={onBlur}
        {...inputAttributes}
      />
      {type === "radio" && label &&
        <>
          <label
            htmlFor={id}
            className="Input__radioLabel"
          >
            <span
              className="Input__radioCheck"
            />
            <span classname="Input__radioContent">
              <span className="Input__radioText">
                {label}
              </span>
              {description &&
                <span className="Input__radioDescription">
                  {description}
                </span>
              }
            </span>
          </label>
        </>
      }
    </>
  );
}

export default Input;
