import React, { createContext, useState, useContext } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';
import routes from 'routes';
import parseErrorMessage from 'utils/parseMessage';

const AppDataContext = createContext();

export const AppDataProvider = ({ children }) => {
  const defaultConfirmDialog = {
    open: false,
    type: "",
    title: "",
    message: "",
    onDecline: () => { },
    onConfirm: () => { },
  };

  const [confirmDialog, setConfirmDialog] = useState(defaultConfirmDialog);
  const removeConfirmDialog = () => setConfirmDialog(defaultConfirmDialog);

  const createConfirmDialog = ({ type, title, message, onConfirm, onDecline }) => {
    setConfirmDialog({
      open: true,
      type,
      title,
      message,
      onDecline: () => {
        if (onDecline) onDecline();
        removeConfirmDialog();
      },
      onConfirm: () => {
        if (onConfirm) onConfirm();
        removeConfirmDialog();
      }
    });
  };

  const [pathBase, setPathBase] = useState(null);
  const [error, setError] = useState(null);
  const location = useLocation();

  const pathPrefix = "/app/";

  const createError = (message) => {
    const parsedMessage = parseErrorMessage(message);

    const error = {
      timestamp: Date.now(),
      url: location.pathname,
      message: parsedMessage.message,
      messageObject: parsedMessage.messageObject
    };

    console.log("Error", error.message);
    setError(error);
  };


  const resetError = () => {
    setError(null);
  }

  const joinPaths = (...paths) => {
    const path = paths.join('/').replace(/\/+/g, '/');
    return path;
  }

  const verifyPath = (path) => {
    const matches = matchRoutes(routes, { pathname: path }, pathPrefix);
    const isValid = matches && matches.some(match => match.route.path && match.route.path !== '*');
    return isValid;
  }

  const subpathsFilter = (subpaths) => {
    return subpaths.filter(Boolean);
  }

  const setPath = (pathBase) => {
    const pathBaseWithPrefix = joinPaths(pathPrefix, pathBase);
    setPathBase(pathBaseWithPrefix);
  }

  const getPath = (...subpaths) => {
    if (pathBase === null) return "/app/";
    const validSubpaths = subpathsFilter(subpaths);
    const path = joinPaths(pathBase, ...validSubpaths);
    if (!verifyPath(path)) console.log(`Path \`${path}\` is not valid`);
    return path;
  }

  const resetPath = () => {
    setPath(null);
  }

  const getPrePath = (...subpaths) => {
    if (subpaths.join("") === "" || subpaths.join("") === "/") return pathPrefix;
    const validSubpaths = subpathsFilter(subpaths);
    const path = joinPaths(pathPrefix, ...validSubpaths);
    if (!verifyPath(path)) console.log(`Path \`${path}\` is not valid`);
    return path;
  }

  const getCustomPath = (...subpaths) => {
    if (subpaths.join("") === "" || subpaths.join("") === "/") return pathPrefix;
    const validSubpaths = subpathsFilter(subpaths);
    const path = joinPaths(pathPrefix, ...validSubpaths);
    if (!verifyPath(path)) console.log(`Path \`${path}\` is not valid`);
    return path;
  }

  return (
    <AppDataContext.Provider
      value={{
        setPath,
        getPath,
        resetPath,
        getPrePath,
        getCustomPath,
        createError,
        error,
        resetError,
        confirmDialog,
        createConfirmDialog,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};

export const useAppData = () => {
  const context = useContext(AppDataContext);
  if (!context) {
    throw new Error('useAppData must be used within an AppDataProvider');
  }
  return context;
};
