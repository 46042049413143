import 'App.scss';
import routes from 'routes';
import { useRoutes } from 'react-router-dom';
import { useAppData } from 'hooks/useAppData';
import ErrorPage from 'pages/ErrorPage';
import Page from 'components/organisms/Page';
import ConfirmDialog from 'components/molecules/ConfirmDialog';

function App() {
  const element = useRoutes(routes);
  const { error, confirmDialog } = useAppData();

  if (error) {
    return (
      <div className="App">
        <Page
          title="An error has occurred"
          size="small"
          sidebar={false}
          isError
        >
          <ErrorPage
            error={error}
          />
        </Page>
      </div>
    );
  }

  return (
    <div className="App">
      {
        confirmDialog.open &&
        <ConfirmDialog
          open={confirmDialog.open}
          type={confirmDialog.type}
          title={confirmDialog.title}
          message={confirmDialog.message}
          onConfirm={confirmDialog.onConfirm}
          onDecline={confirmDialog.onDecline}
        />
      }
      {element}
    </div>
  );
}

export default App;
