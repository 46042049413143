import './style.scss';
import MenuItem from 'components/atoms/MenuItem';
import Icon from 'components/atoms/Icon';
import AccountMenu from 'components/molecules/AccountMenu';
import { useAppData } from 'hooks/useAppData';

const platformMenuItems = [
  {
    id: "dashboard",
    label: "Dashboard",
    href: "/dashboard",
    icon: "dashboard",
  },
  {
    id: "sst",
    label: "Server-side tracking",
    href: "/tracking",
    icon: "server",
  },
  {
    id: "warehouse",
    label: "Data warehouse",
    href: "/setup_warehouse",
    icon: "datasources",
    hidden: true
  },
  {
    id: "datasources",
    label: "Data sources",
    href: "/datasources",
    icon: "datasources",
  },
  {
    id: "monitor",
    label: "GDPR monitoring",
    href: "/monitor",
    icon: "datasources",
  }
];

const toolsMenuItems = [
  {
    id: "copilot",
    label: "Copilot",
    href: "/copilot",
    icon: "terminal",
  },
  {
    id: "impact",
    label: "Impact analysis",
    href: "/impact",
    icon: "impact",
  },
  {
    id: "datavis",
    label: "Data visualisation",
    href: "/datavis",
    icon: "datavis",
  }
];

function Menu(props) {
  const { getPath } = useAppData();

  const SubMenu = (props) => {
    if (!props.items) return "";

    const className = props.className ? props.className : "";
    const subMenuItems = props.items;

    return (
      <div className={"Menu__submenu " + (className ? className : "")}>
        {props.title && <div className={"Menu__title " + (className ? className + "__title" : "")}>{props.title}</div>}
        <div className={"Menu__items " + (className ? className + "__items" : "")}>
          {subMenuItems.map((item, index) => {
            if (item.hidden) return "";

            return (
              <MenuItem
                className={"Menu__item " + (className ? className + "__item" : "")}
                href={getPath(item.href)}
                key={index}
              >
                {item.icon && <Icon className="MenuItem__Icon Menu__icon" icon={item.icon} />}
                <span className="MenuItem__label">{item.label}</span>
              </MenuItem>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="Menu">
      <div className="Menu__primary">
        <SubMenu items={platformMenuItems} className="PlatformMenu" />
        <SubMenu title="Business Apps" items={toolsMenuItems} />
      </div>
      <div className="Menu__secondary">
        <AccountMenu />
      </div>
    </div>
  );
}

export default Menu;
