import Tags from 'components/molecules/Tags';
import './style.scss';

function PageHeader(props) {
  const { pretitle, title, tags, description, button, alt, big } = props;
  return (
    <div
      className={
        "PageHeader"
        + (alt ? " PageHeader--alt" : "")
        + (big ? " PageHeader--big" : "")
      }
    >
      <div className="PageHeader__titles">
        {pretitle && <div className="PageHeader__pretitle">{pretitle}</div>}
        <h1 className="PageHeader__title">{title}</h1>
        {tags && <Tags tags={tags} className="PageHeader__tags" />}
        {description && <div className="PageHeader__description">{description}</div>}
      </div>
      {button && <div className="PageHeader__button">{button}</div>}
    </div>
  );
}

export default PageHeader;
