import Button from 'components/atoms/Button';
import './style.scss';
import OutsideBox from 'outside/components/OutsideBox';
import { useEffect } from 'react';
import LoadingDots from 'components/atoms/LoadingDots';
import YouTube from 'react-youtube';

const HubSpotForm = (props) => {
  const { portalId, formId, submitLabel, locale } = props;

  const thanks_en = `
  <div class="CTAHero__thanks">
    <span>Great!</span>
    Check your email to continue.
  </div>
`;
  const thanks_sv = `
  <div class="CTAHero__thanks">
    <span>Grymt!</span>
    Kolla din e-post för att fortsätta.
  </div>
`;

  const thanksHTML = locale === "sv" ? thanks_sv : thanks_en;

  useEffect(() => {
    const loadHubSpotScript = () => {
      if (window.hbspt) {
        createForm();
        return;
      }

      const script = document.createElement('script');
      script.src = '//js.hsforms.net/forms/v2.js';
      script.async = true;
      script.defer = true;
      script.onload = () => createForm();
      document.body.appendChild(script);
    };

    const createForm = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: portalId,
          formId: formId,
          target: '#hubspotForm',
          locale: locale,
          translations: {
            sv: {
              required: "Du måste ange en e-post",
              submitText: submitLabel,
              fieldLabels: {
                email: "Fyll i e-mail",
              }
            },
            en: {
              required: "You need to enter an email",
              submitText: submitLabel,
              fieldLabels: {
                email: "Enter your email",
              }
            }
          },
          onFormReady: () => {
            const loadingDots = document.querySelector('.CTAHero__form .LoadingDots');
            if (loadingDots) loadingDots.style.display = "none";
            const labelText = document.querySelector('.CTAHero__form #label-email-' + formId + ' span');
            const input = document.querySelector('.CTAHero__form #email-' + formId);
            if (input && !!labelText.textContent) {
              input.placeholder = labelText.textContent;
            }
          },
          onFormSubmitted: () => {
            document.getElementById('hubspotForm').innerHTML = thanksHTML;
          }
        });
      }
    };

    loadHubSpotScript();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalId, formId]);

  return <div id="hubspotForm"></div>;
};

function CTAHero(props) {
  const {
    className = "",
    lang,
    pretitle,
    title,
    usps,
    tagline,
    cta,
    button,
    media,
    youtube
  } = props;
  const formId = "0797f463-17eb-470c-844f-a5d81265e8d1";

  const buttonLabel_sv = "Kom igång";
  const buttonLabel_en = "Sign up";

  const buttonLabel = lang === "sv" ? buttonLabel_sv : buttonLabel_en;

  return (
    <OutsideBox classBase={"CTAHero " + className} nobg>
      <div className={"CTAHero " + className}>
        <div className={"CTAHero__inner"}>

          {pretitle && (
            <div className="CTAHero__pretitle">{pretitle}</div>
          )}

          {title && (
            <h1 className="CTAHero__title">{title}</h1>
          )}

          {usps && (
            <div className="CTAHero__usps">{usps}</div>
          )}

          {tagline && (
            <div className="CTAHero__tagline">{tagline}</div>
          )}

          {cta === "link" && (
            <Button type="link" className="CTAHero__cta" href="/login">{buttonLabel}</Button>
          )}

          {cta === "hubspot" && (
            <div className={"CTAHero__form" + (lang === "sv" ? " CTAHero__form--sv" : "")}>
              <LoadingDots />
              <HubSpotForm
                submitLabel={buttonLabel}
                portalId="25843356"
                formId={formId}
                locale={lang}
              />
            </div>
          )}

          {media && (
            <div className="CTAHero__media">{media}</div>
          )}

          {youtube && (
            <YouTube
              videoId={youtube}
              className="CTAHero__youtube"
              opts={{
                height: '100%',
                width: '100%',
                playerVars: {
                  autoplay: 0,
                  controls: 1,
                  modestbranding: 1,
                  rel: 0,
                  showinfo: 0
                }
              }}
            />
          )}

          {button}
        </div>
      </div>
    </OutsideBox>
  );
}

export default CTAHero;