import Icon from 'components/atoms/Icon';
import './style.scss';
import { Link } from 'react-router-dom';
import { useAppData } from 'hooks/useAppData';

export const onboardingTasks = [
  {
    id: "account",
    title: "Create an account",
    description: "Enter your email and get going",
    href: "/",
    deps: [],
    done: 1,
  },
  {
    id: "billing",
    title: "Set up billing",
    description: "Get started by configuring your payment method. We offer pricing options suitable for all sizes and needs.",
    href: "/billing",
    deps: ["account"],
    done: 0,
  },
  {
    id: "sst",
    title: "Add server-side tracking",
    description: "Ensure GDPR compliance by setting up EU-based server-side tagging that anonymises private user data.",
    href: "/tracking",
    deps: ["billing"],
    done: 0,
  },
  {
    id: "warehouse",
    title: "Set up your data warehouse",
    description: "Set up a BigQuery data warehouse to efficiently store and manage your data wherever it comes from.",
    href: "/setup_warehouse",
    deps: ["billing"],
    done: 0,
  },
  {
    id: "datasources",
    title: "Connect to data sources",
    description: "Add data connectors to access information from 150+ different sources and enhance your analytics capabilities.",
    href: "/datasources",
    deps: ["warehouse"],
    done: 0,
  },
  // {
  //   id: "copilot",
  //   title: "Query your data with Data copilot",
  //   description: "Use plain english to query and transform your data with Cogny's GPT-4-powered Data copilot.",
  //   href: "/copilot",
  //   deps: ["datasources"],
  //   done: 0,
  // },
  // {
  //   id: "impact",
  //   title: "Discover trends with Impact analysis",
  //   description: "Dive deep into long-term user data with an automated impact analysis, revealing trends and insights.",
  //   href: "/impact",
  //   deps: ["datasources"],
  //   done: 0,
  // },
  // {
  //   id: "datavis",
  //   title: "Visualise your data with Looker Studio",
  //   description: "Ensure GDPR compliance by setting up European server-side tagging and prioritize user data privacy.",
  //   href: "/datavis/start",
  //   deps: ["warehouse"],
  //   done: 0,
  // }
];

// const onboardingApps = {
//   id: "apps",
//   title: "Get to know our business apps",
//   description: "Explore and transform your data with Data copilot and reveal trends with Impact analysis.",
//   href: "/copilot",
//   deps: ["datasources"],
//   done: 0,
// };

const TaskContainer = (props) => {
  const { getPath } = useAppData();
  const { status, href, className, children } = props;

  if (status === "ready") {
    return (
      <Link to={getPath(href)} className={className}>
        {children}
        <Icon icon="arrowRight" className="OnboardingTask__arrow" />
      </Link>
    );
  } else {
    return (
      <div className={className}>
        {children}
        {typeof status === "undefined" && <div style={{ marginLeft: "auto", whiteSpace: "nowrap", opacity: 0.7, textTransform: "uppercase", fontSize: 12, fontWeight: 500 }}>Error: Can't read status</div>}
      </div>
    );
  }
}

const OnboardingTask = (props) => {
  const { status } = props;
  const { title, description, href } = props.data;

  const statusClass = " OnboardingTask--" + status;

  return (
    <TaskContainer status={status} href={href} className={"OnboardingTask" + statusClass}>
      {status === "done" &&
        <Icon icon="check" className="OnboardingTask__check" />
      }

      <div className="OnboardingTask__info">
        <h3 className="OnboardingTask__title">{title}</h3>

        {status !== "done" &&
          <div className="OnboardingTask__description">{description}</div>
        }
      </div>
    </TaskContainer>
  );
}

function OnboardingChecklist(props) {
  const { flags, onClick } = props;

  if (!flags) return "";

  const coreTasks = ['account', 'billing', 'sst', 'warehouse', 'datasources'];
  let appStatuses = [];

  let enabledCounter = 0;
  let checkedCounter = 0;
  for (const key in flags) {
    if (flags[key] !== null) {
      enabledCounter++;

      if (flags[key]) {
        checkedCounter++
      }
    }
  }
  const totalTasks = enabledCounter;
  const percentDone = Math.round(checkedCounter / totalTasks * 100);

  if (percentDone === 100) return;

  const appTasks = onboardingTasks.filter(item => coreTasks.indexOf(item.id) === -1);

  appTasks.forEach(item => {

    const flag = flags[item.id];
    if (typeof flag === "undefined" || flag === null) return false;

    const deps = item.deps;
    const depsCount = deps.length;
    let doneDepCount = 0;

    deps.forEach(function (dep) {
      const foundDep = flags[dep];
      if (!!foundDep) {
        doneDepCount++;
      }
    });

    const disabled = depsCount > doneDepCount;
    const status = flag ? "done" : disabled ? "disabled" : "ready";

    appStatuses.push(status);
  })

  // const appsDone = appStatuses.filter(item => item === "done").length === appStatuses.length;
  // const appsDisabled = appStatuses.filter(item => item === "disabled").length;

  // const appsStatus = appsDone ? "done" : appsDisabled ? "disabled" : "ready";

  return (
    <div className="OnboardingChecklist">
      <div className="OnboardingChecklist__header">
        <h2 className="OnboardingChecklist__title">Onboarding checklist</h2>
        <div className="OnboardingChecklist__description">This is a checklist for you to complete your onboarding. Please keep going to unlock the full power of Cogny.</div>
      </div>

      <div className="OnboardingChecklist__progress">
        <div className={"OnboardingChecklist__progressbar" + (percentDone === 100 ? " OnboardingChecklist__progressbar--full" : "")} style={{ width: percentDone + "%" }}><span>{percentDone}%</span></div>
      </div>

      <div className="OnboardingChecklist__tasks">
        {onboardingTasks.filter(item => coreTasks.indexOf(item.id) !== -1).map(item => {

          const flag = flags[item.id];
          if (typeof flag === "undefined" || flag === null) return false;

          const deps = item.deps;
          const depsCount = deps.length;
          let doneDepCount = 0;

          deps.forEach(function (dep) {
            const foundDep = flags[dep];
            if (!!foundDep) {
              doneDepCount++;
            }
          });

          const disabled = depsCount > doneDepCount;
          const status = flag ? "done" : disabled ? "disabled" : "ready";

          return (
            <OnboardingTask key={item.id} data={item} status={status} />
          );
        })}

        {/* <OnboardingTask key={onboardingApps.id} data={onboardingApps} status={appsStatus} /> */}
      </div>

      {false && (!!flags.sst || !!flags.warehouse) && <button className="OnboardingChecklist__toggle" onClick={onClick}>Hide this checklist</button>}
    </div>
  );
}

export default OnboardingChecklist;
