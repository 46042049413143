import CognyAPI from 'components/_classes/CognyAPI';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

export const deps = {
  account: [],
  billing: ["account"],
  sst: ["billing"],
  monitor: ["billing"],
  warehouse: ["billing"],
  datasources: ["warehouse"],
  copilot: ["datasources"],
  impact: ["copilot"],
  datavis: ["warehouse"],
}

const DependencyContext = createContext();

export const DependencyProvider = ({ children }) => {
  const [accessStates, setAccessStates] = useState({});
  const [cookies] = useCookies(["token"]);
  const [dependencyWarehouseId, setDependencyWarehouseId] = useState("");

  useEffect(() => {
    setAccessStates({});  // Clear all access states
  }, [dependencyWarehouseId]);

  const updateWarehouseId = (newId) => {
    setDependencyWarehouseId(newId);
  };

  const hasAccount = () => Promise.resolve(true);

  const hasBilling = async (warehouseId) => {
    try {
      const api = new CognyAPI(cookies.token);
      const result = await api.getBillingLink(warehouseId)
      return (result.billing_type === "stripe" && result.link !== "") || result.billing_type === "invoice";
    } catch (error) {
      console.log("Error in hasBilling:", error);
      return false;
    }
  }

  const hasSST = async (warehouseId) => {
    try {
      const api = new CognyAPI(cookies.token);
      const result = await api.getSSGTMs(warehouseId);
      return result.filter(item => item.status === "active").length > 0;
    } catch (error) {
      console.log("Error in hasSST:", error);
      return false;
    }
  };

  const hasMonitor = async (warehouseId) => {
    try {
      const api = new CognyAPI(cookies.token);
      const result = await api.getSSGTMs(warehouseId);
      return result.filter(item => item.status === "active").length > 0;
    } catch (error) {
      console.log("Error in hasMonitor:", error);
      return false;
    }
  };

  const hasWarehouse = async (warehouseId) => {
    try {
      const api = new CognyAPI(cookies.token);
      const result = await api.getWarehouse(warehouseId);
      return result.status === "Active";
    } catch (error) {
      console.log("Error in hasWarehouse:", error);
      return false;
    }
  };

  const hasDatasources = async (warehouseId) => {
    try {
      const api = new CognyAPI(cookies.token);
      const result = await api.getWarehouse(warehouseId);
      return result.no_datasources ? result.no_datasources > 0 : false;
    } catch (error) {
      console.log("Error in hasDatasources:", error);
      return false;
    }
  };

  const hasCopilot = async (warehouseId) => {
    try {
      const api = new CognyAPI(cookies.token);
      const result = await api.getXForms(warehouseId);
      return result.length > 0;
    } catch (error) {
      console.log("Error in hasCopilot:", error);
      return false;
    }
  };

  const hasImpactAnalysis = async (warehouseId) => {
    try {
      const api = new CognyAPI(cookies.token);
      const result = await api.getImpacts(warehouseId);
      return result.length > 0;
    } catch (error) {
      console.log("Error in hasImpactAnalysis:", error);
      return false;
    }
  };

  const checkOnboarding = async (warehouseId) => {
    const reformatResult = (result) => {
      const newFormat = {
        account: true,
        billing: result.has_billing,
        warehouse: result.has_warehouse,
        sst: result.has_sst,
        datasources: result.has_datasource,
        // copilot: result.has_copilot,
        // impact: result.has_impact_analysis
      }

      return newFormat;
    }

    try {
      const api = new CognyAPI(cookies.token);
      const result = await api.getOnboarding(warehouseId);
      return reformatResult(result);
    } catch (error) {
      console.log("Error in checkOnboarding:", error);
      return false;
    }
  }

  const checkFunctions = {
    account: hasAccount,
    billing: hasBilling,
    warehouse: hasWarehouse,
    sst: hasSST,
    monitor: hasMonitor,
    datasources: hasDatasources,
    copilot: hasCopilot,
    impact: hasImpactAnalysis
  };

  const checkAccess = async (page, warehouseId) => {
    if (!page) {
      return false;
    }

    if (!warehouseId) {
      return false;
    }

    setDependencyWarehouseId(warehouseId);

    const dependencies = deps[page] || [];

    for (const dependency of dependencies) {
      // Check if the access state is already known
      if (accessStates[dependency] !== undefined) {
        if (!accessStates[dependency]) {
          return false;
        }
        continue;
      }

      const checkFunction = checkFunctions[dependency];
      if (!checkFunction) {
        console.log(`No check function defined for dependency: ${dependency}`);
        return false;
      }

      // If the check function requires warehouseId, pass it.
      const hasAccess = await checkFunction(warehouseId);

      // Store the access result
      setAccessStates(prev => ({ ...prev, [dependency]: hasAccess }));

      if (!hasAccess) {
        return false;
      }
    }
    return true;
  }

  return (
    <DependencyContext.Provider value={{ checkAccess, checkOnboarding, updateWarehouseId }}>
      {children}
    </DependencyContext.Provider>
  );
};

export const useDependencies = () => {
  const context = useContext(DependencyContext);
  if (!context) {
    throw new Error("useDependencies must be used within a DependencyProvider");
  }
  return context;
};
