import './style.scss';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import Select from 'react-select';
import Textarea from 'components/atoms/Textarea';

const FieldType = (props) => {
  const {
    type,
    className,
    classNamePrefix,
    id,
    name,
    value,
    disabled,
    onChange,
    onFocus,
    onBlur,
    options,
    placeholder,
    defaultValue,
    components,
    rows,
    submit,
    setRef
  } = props;

  if (type === "text" || type === "email") {
    return (
      <Input
        className={className}
        name={name ?? id}
        id={id}
        type={type}
        value={value}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  } else if (type === "textarea") {
    return (
      <Textarea
        className={className}
        name={name ?? id}
        id={id}
        rows={rows}
        onChange={onChange}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
      >{value}</Textarea>
    );
  } else if (type === "dynamictextarea") {
    return (
      <Textarea
        className={className}
        name={name ?? id}
        id={id}
        rows={rows}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        dynamic
        submit={submit}
        setRef={setRef}
      >{value}</Textarea>
    );
  } else if (type === "select") {
    if (!options) return false;
    return (
      <Select
        id={id}
        className={"Select " + className}
        classNamePrefix={classNamePrefix}
        options={options}
        placeholder={placeholder}
        isSearchable
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        components={components}
        menuPosition="fixed"
      />
    );
  } else if (type === "multiselect") {
    if (!options) return false;
    return (
      <Select
        id={id}
        className={"Select " + className}
        classNamePrefix={classNamePrefix}
        options={options}
        placeholder={placeholder}
        isMulti
        isClearable
        isSearchable
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        components={components}
        menuPosition="fixed"
      />
    );
  } else if (type === "radiogroup") {
    return (
      <div className={"Radiogroup " + className}>
        {options.map((option, index) => {
          return (
            <div className="Radiogroup__option">
              <Input
                key={index}
                type="radio"
                name={id}
                id={option.value}
                value={option.value}
                checked={value === option.value}
                onChange={onChange}
                label={option.label ?? option.value}
                description={option.description}
              />
            </div>
          );
        })}
      </div>
    );
  } else if (type === "segmented") {
    return (
      <div className={"Segmented " + className}>
        {options.map((option, index) => {
          return (
            <Input
              key={index}
              type="radio"
              name={name ?? id}
              id={option.value}
              value={option.value}
              checked={value === option.value}
              onChange={onChange}
            />
          );
        })}
      </div>
    );
  } else {
    return false;
  }
}

function Field(props) {
  const {
    className = "",
    classNamePrefix = "Field",
    id,
    name,
    label,
    type,
    value,
    disabled,
    onChange,
    onFocus,
    onBlur,
    options,
    placeholder,
    components,
    defaultValue,
    rows,
    submit,
    setRef,
    validation,
    description,
    action
  } = props;

  return (
    <div className={"Field " + className}>
      <Label
        className={classNamePrefix + "__label Field__label"}
        htmlFor={id}
        label={label ?? name}
      />

      {description &&
        <div className={classNamePrefix + "__description Field__description"}>
          {description}
        </div>
      }

      <div className={classNamePrefix + "__row Field__row"}>
        <div className={classNamePrefix + "__input Field__input"}>
          <FieldType
            id={id}
            className={classNamePrefix + "__field Field__field"}
            classNamePrefix={classNamePrefix}
            name={name}
            type={type}
            value={value}
            disabled={disabled}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            options={options}
            placeholder={placeholder}
            components={components}
            defaultValue={defaultValue}
            rows={rows}
            submit={submit}
            setRef={setRef}
          />

          {validation}
        </div>
        {action &&
          <div className={classNamePrefix + "__action Field__action"}>
            {action}
          </div>
        }
      </div>

    </div>);
}

export default Field;