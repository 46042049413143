class CognyAPI {
  constructor(token) {
    if (!token) {
      throw (new Error('Missing API token'));
    }

    this.token = token;
    this.anonymous = token === 'anonymous';
    this.apiHost = process.env.REACT_APP_API_BASE_URL;
    this.url = this.apiHost + "/api/v1";
  }

  getApiHost() {
    return this.apiHost;
  }

  getToken() {
    return this.token;
  }

  async _call(endpoint, options = { method: "GET" }) {
    const url = this.url;

    let plainTextReturn = options.plainTextReturn;

    let addedOptions = options;
    delete addedOptions.plainTextReturn;
    if (!this.anonymous) {
      addedOptions.headers = {
        ...addedOptions.headers,
        ...{
          /* These needs updated headers in API to work
  
          'Content-Type': 'application/json',
          */
          'Authorization': 'Bearer ' + this.token
        }
      }
    }

    return await Promise.race([
      fetch(url + endpoint, addedOptions)
        .then(async res => {


          if (!res.ok) {
            if (res.status === 401) {
              this._deleteCookie('token');
              throw new Error('Your session has expired');
            }
          }

          try {
            if (res.ok && plainTextReturn) {
              return await res.text();
            }

            const data = await res.json();
            if (!data) {
              throw new Error('No data received');
            }

            if (!res.ok) {
              const error = new Error(data.message || "Something went wrong");
              error.code = data.code || res.status;
              throw error;
            }
            return data;
          } catch (e) {
            throw new Error('Something went wrong');
          }
        }),
      /* inital customer setup takes a really long time so 5 min should do it */
      new Promise((_, reject) => setTimeout(() => reject(new Error('The API connection timed out :(')), 300000))
    ]).catch(e => {
      throw e
    })
  }

  _error(message = "") {
    return new Promise((_, reject) => reject(new Error(message)));
  }

  _deleteCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  async getBalance() {
    return await this._call('/credits/balance')
  }


  async addCoins(postData) {
    if (!postData || typeof postData.amount === "undefined") return this._error('There\'s something wrong with the coins form data');

    return await this._call(
      '/credits/balance',
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async sendMessage(postData) {
    if (!postData) return this._error('There\'s something wrong with the contact form data');

    return await this._call(
      '/contact',
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }



  async setupWarehouse(project, postData) {
    return await this._call(
      '/warehouses/' + project + '/setup',
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async addWarehouse(postData) {
    return await this._call(
      '/warehouses/add',
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async reportBug(postData) {
    return await this._call(
      '/bugreport',
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async getOnboarding(id) {
    return await this._call('/onboarding/' + id)
  }

  async getBillingLink(id) {
    return await this._call('/billing/' + id + "/manage")
  }

  async getBilling(id) {
    return await this._call('/billing/' + id)
  }

  async getWarehouses() {
    return await this._call('/warehouses')
  }

  async getWarehouse(id) {
    return await this._call('/warehouses/' + id)
  }

  async getWarehouseAccess(id) {
    return await this._call('/warehouses/' + id + "/access")
  }

  async getWarehouseCreditsStats(id) {
    return await this._call('/warehouses/' + id + '/stats/credits')
  }

  async getWarehouseProcessingStats(id) {
    return await this._call('/warehouses/' + id + '/stats/processing')
  }

  async getWarehouseStorageStats(id) {
    return await this._call('/warehouses/' + id + '/stats/storage')
  }

  async addCopilotPreview(postData) {
    return await this._call(
      '/kpis',
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async sendCopilotPreviewMessage(sessionId, postData) {
    return await this._call(
      '/kpis/' + sessionId,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async getCopilotPreview(sessionId) {
    return await this._call('/kpis/' + sessionId)
  }

  async addCopilotSession(project, postData) {
    return await this._call(
      '/copilot/' + project,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async sendCopilotSessionMessage(project, sessionId, postData) {
    return await this._call(
      '/copilot/' + project + "/" + sessionId,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async listCopilotSessions(project) {
    return await this._call('/copilot/' + project)
  }

  async getCopilotSession(project, id) {
    return await this._call('/copilot/' + project + "/" + id)
  }

  async deleteCopilotSession(project, id) {
    if (!id) return this._error('Missing task id value');
    return await this._call('/copilot/' + project + '/' + id,
      {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
  }

  async updateXForm(warehouse, id, postData) {

    return await this._call(
      '/xforms/' + warehouse + "/" + id,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async getXForms(project) {
    return await this._call('/xforms/' + project)
  }

  async getXFormDetail(project, id) {
    return await this._call('/xforms/' + project + "/" + id)
  }

  async getXFormSampleData(project, id) {
    return await this._call('/xforms/' + project + "/" + id + "/data")
  }

  async addXForm(project, postData) {
    return await this._call(
      '/xforms/' + project,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async deleteXForm(project, id) {
    if (!id) return this._error('Missing xform id value');
    return await this._call('/xforms/' + project + '/' + id,
      {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
  }


  async getImpacts(project) {
    return await this._call('/impacts/' + project)
  }

  async getImpact(project, id) {
    if (!id) return this._error('Missing task id value');
    return await this._call('/impacts/' + project + '/' + id)
  }

  async addSSGTM(project, postData) {
    return await this._call(
      '/ssgtm/' + project,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async updateSSGTM(project, postData) {
    return await this._call(
      '/ssgtm/' + project + "/" + postData.id,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async verifySSGTM(project, id) {
    return await this._call('/ssgtm/' + project + '/' + id + '/verify')
  }


  async getSSGTMStats(project, id) {
    return await this._call('/ssgtm/' + project + '/' + id + "/stats")
  }

  async getSSGTM(project, id) {
    return await this._call('/ssgtm/' + project + '/' + id)
  }

  async getSSGTMs(project) {
    return await this._call('/ssgtm/' + project)
  }

  async deleteSSGTM(project, id) {
    if (!id) return this._error('Missing task id value');
    return await this._call('/ssgtm/' + project + '/' + id,
      {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
  }

  async getDatasources(project) {
    return await this._call('/datasources/' + project)
  }

  async getDatasourceDetails(project) {
    return await this._call('/datasources/' + project + '/details')
  }

  async deleteDatasource(project, id) {
    if (!id) return this._error('Missing task id value');
    return await this._call('/datasources/' + project + '/' + id,
      {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
  }

  async getDatasource(project, id) {
    if (!id) return this._error('Missing task id value');
    return await this._call('/datasources/' + project + '/' + id)
  }


  async getTasks(project) {
    return await this._call('/questions/' + project)
  }

  async getTask(project, id) {
    if (!id) return this._error('Missing task id value');
    return await this._call('/questions/' + project + '/' + id)
  }

  async addWarehouseFeature(project, postData) {
    return await this._call(
      '/warehouses/' + project + '/feature/add',
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async addImpactAnalysis(project, postData) {
    return await this._call(
      '/impacts/' + project,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async rerunImpactAnalysis(project, id) {
    if (!id) return this._error('Missing task id value');
    return await this._call('/impacts/' + project + '/rerun/' + id,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
  }

  async deleteImpactAnalysis(project, id) {
    if (!id) return this._error('Missing task id value');
    return await this._call('/impacts/' + project + '/' + id,
      {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
  }

  async addAccess(project, postData) {
    return await this._call(
      '/warehouses/' + project + '/access/add',
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async addDatasource(project, postData) {
    if (!postData || typeof postData.amount !== "undefined") return this._error('There\'s something wrong with the task form data');

    return await this._call(
      '/datasources/' + project,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async addTask(project, postData) {
    if (!postData || typeof postData.amount !== "undefined") return this._error('There\'s something wrong with the task form data');

    return await this._call(
      '/questions/' + project,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async updateTask(postData) {
    if (!postData || typeof postData.amount !== "undefined") return this._error('There\'s something wrong with the task form data');

    return await this._call(
      '/questions',
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async getGDPRMonitors(project) {
    return await this._call('/gdpr_monitor/' + project)
  }

  async getGDPRMonitor(project, id) {
    if (!id) return this._error('Missing monitor id value');
    return await this._call('/gdpr_monitor/' + project + "/" + id)
  }

  async addGDPRMonitor(project, postData) {
    return await this._call(
      '/gdpr_monitor/' + project,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async updateGDPRMonitor(project, postData) {
    return await this._call(
      '/gdpr_monitor/' + project + "/" + postData.id,
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async deleteGDPRMonitor(project, id) {
    if (!id) return this._error('Missing monitor id value');
    return await this._call('/gdpr_monitor/' + project + '/' + id,
      {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        plainTextReturn: true
      });
  }

  async getSimulated(project) {
    // Simulate a delay of 3 seconds (3000 milliseconds)
    await new Promise(resolve => setTimeout(resolve, 3000));

    // Simulate a response
    const simulatedResponse = {
      success: true,
      message: "Simulated response received after 3 seconds."
    };

    return simulatedResponse;
  }

  async addSimulated(project, postData) {
    // Simulate a delay of 3 seconds (3000 milliseconds)
    await new Promise(resolve => setTimeout(resolve, 3000));

    // Simulate a response
    const simulatedResponse = {
      success: true,
      message: "Simulated response received after 3 seconds."
    };

    return simulatedResponse;
  }

  async updateSimulated(project, postData) {
    // Simulate a delay of 3 seconds (3000 milliseconds)
    await new Promise(resolve => setTimeout(resolve, 3000));

    // Simulate a response
    const simulatedResponse = {
      success: true,
      message: "Simulated response received after 3 seconds."
    };

    return simulatedResponse;
  }

  async updateSimulatedReturnData(project, postData) {
    // Simulate a delay of 3 seconds (3000 milliseconds)
    await new Promise(resolve => setTimeout(resolve, 3000));

    // Simulate a response
    const simulatedResponse = {
      ...postData,
    };

    return simulatedResponse;
  }

  async deleteSimulated(project, id) {
    // Simulate a delay of 3 seconds (3000 milliseconds)
    await new Promise(resolve => setTimeout(resolve, 3000));

    // Simulate a response
    const simulatedResponse = {
      success: true,
      message: "Simulated response received after 3 seconds."
    };

    return simulatedResponse;
  }
}

export default CognyAPI;
