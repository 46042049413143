import "./style.scss";
import { useCallback, useEffect, useRef } from "react";
import Button from "components/atoms/Button";

function ConfirmDialog(props) {
  const {
    open,
    type = "confirm",
    title,
    message,
    onConfirm,
    onDecline
  } = props;

  const ref = useRef(null);

  const dialogTypes = [
    {
      type: "delete",
      className: "ConfirmDialog--delete",
      primaryLabel: "Delete",
      primaryIcon: "trashcan",
      secondaryLabel: "Cancel",
    },
    {
      type: "save",
      className: "ConfirmDialog--save",
      primaryLabel: "Save",
      primaryIcon: "arrowRight",
      secondaryLabel: "Cancel",
    },
    {
      type: "info",
      className: "ConfirmDialog--info",
      primaryLabel: "OK",
      primaryIcon: "",
    },
    {
      type: "yesno",
      className: "ConfirmDialog--yesno",
      primaryLabel: "Yes",
      primaryIcon: "",
      secondaryLabel: "No",
    },
    {
      type: "confirm",
      className: "ConfirmDialog--confirm",
      primaryLabel: "Confirm",
      primaryIcon: "",
      secondaryLabel: "Cancel",
    },
  ];

  const getType = (name) => dialogTypes.find(dialogType => dialogType.type === name);
  const currentType = getType(type) || getType("confirm");

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      onDecline();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (open) {
      ref.current?.showModal();
    } else {
      ref.current?.close();
    }
  }, [open]);

  const handleModalClick = (e) => {
    if (e.target.classList.contains('ConfirmDialog')) onDecline();
  }

  if (!open) return null;

  return (
    <dialog
      ref={ref}
      className={
        "ConfirmDialog"
        + (currentType ? " " + currentType.className : "")
      }
      onClick={handleModalClick}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-message"
    >
      {title &&
        <div
          className="ConfirmDialog__header"
        >
          <h1
            id="confirm-dialog-title"
            className="ConfirmDialog__title"
          >
            {title}
          </h1>
        </div>
      }

      {message &&
        <main
          className="ConfirmDialog__main"
        >
          <div
            id="confirm-dialog-message"
            className="ConfirmDialog__message"
          >
            {message}
          </div>
        </main>
      }

      <div
        className="ConfirmDialog__actions"
      >
        {
          currentType.secondaryLabel &&
          <Button
            type="button"
            onClick={onDecline}
            className="ConfirmDialog__button--secondary"
            size="small"
          >
            {currentType.secondaryLabel}
          </Button>
        }

        <Button
          type="submit"
          onClick={onConfirm}
          className="ConfirmDialog__button--primary"
          iconRight={currentType.primaryIcon}
          size="small"
        >
          {currentType.primaryLabel}
        </Button>
      </div>
    </dialog>
  )
};

export default ConfirmDialog;