import { useState, useEffect, useCallback, useRef } from 'react';
import './style.scss';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import LoadingDots from 'components/atoms/LoadingDots';
import ErrorBox from 'components/molecules/ErrorBox';
import CognyAPI from 'components/_classes/CognyAPI';
import PageHeader from 'components/organisms/PageHeader';
import ModalDialog from 'components/molecules/ModalDialog';
import useWindowSize from 'hooks/useWindowSize';
import Form from 'components/atoms/Form';
import Field from 'components/molecules/Field';
import Dropdown from 'components/molecules/Dropdown';
import { useAppData } from 'hooks/useAppData';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import generateSalt from 'utils/generateSalt';
import { pricingTiersData } from 'outside/components/PricingTiers';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import SubmitStatus from 'components/molecules/SubmitStatus';
const debug = false;

const googleConfig = {
  googleClientId: '717495834499-ff194t3iu8oeinm1923vutdl5jhs53rr.apps.googleusercontent.com',
  apiScopes: 'https://www.googleapis.com/auth/tagmanager.edit.containers',
  // Add other configuration settings as needed
};

const getPricingOptions = () => {
  const formatNumber = (num) => {
    const formatWithSuffix = (num, divisor, suffix) => {
      const dividedNum = num / divisor;
      // Check if the number is whole (no decimals) after division
      if (Math.floor(dividedNum) === dividedNum) {
        return dividedNum + suffix; // Return without decimal point
      } else {
        return dividedNum.toFixed(1) + suffix; // Format to one decimal place and add suffix
      }
    }

    if (num >= 1000000000) {
      return formatWithSuffix(num, 1000000000, 'b');
    } else if (num >= 1000000) {
      return formatWithSuffix(num, 1000000, 'm');
    } else if (num >= 1000) {
      return formatWithSuffix(num, 1000, 'k');
    } else {
      return num.toString(); // Convert to string if less than 1000
    }
  }

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const pricingOptions = pricingTiersData.map((tier) => {
    return {
      label: capitalize(tier.name),
      value: tier.name,
      description: "Up to " + formatNumber(tier.events) + " events, " + tier.credits + " credits/month",
    };
  });

  return pricingOptions;
}

const TrackerChart = (props) => {
  const { stats } = props;
  const { width } = useWindowSize();

  function labelFormatter(value) {
    switch (value) {
      case 'requests':
        return 'Requests';
      case 'avg_request_time':
        return 'Avg. request time';
      default:
        return value;
    }
  }

  const currentLocalTime = new Date();
  const timeXMinutesAgo = (minutesAgo) => {
    const time = new Date(currentLocalTime);
    time.setMinutes(currentLocalTime.getMinutes() - minutesAgo);

    const timeHours = time.getHours();
    const timeMinutes = time.getMinutes();
    const pad = (n) => n < 10 ? '0' + n : n;
    return `${pad(timeHours)}:${pad(timeMinutes)}`;
  }

  const CustomTooltip = (props) => {
    const { active, payload, label, unit, title } = props;
    if (active && payload && payload.length) {
      const time = timeXMinutesAgo(label);

      return (
        <div className="TrackingChart__tooltip" style={{ backgroundColor: 'black', padding: 16 }}>
          <div className="TrackingChart__tooltip-title" style={{ fontWeight: 600, fontSize: 12 }}>{label} {title} <span style={{ opacity: "0.5" }}>{time}</span></div>
          <div className="TrackingChart__tooltip-content">
            {payload.map((item, index) => {
              return (
                <div
                  key={item.name}
                  style={{
                    color: item.color,
                    lineHeight: "1em",
                    fontSize: 12,
                    display: "flex",
                    alignItems: "center",
                    marginTop: 6,
                    gap: 4
                  }}
                >
                  <span>{labelFormatter(item.name)}</span>
                  <span style={{
                    backgroundColor: item.color,
                    borderRadius: 16,
                    paddingLeft: 6,
                    paddingRight: 6,
                    paddingTop: 2,
                    paddingBottom: 2,
                    fontWeight: 600,
                    color: "rgba(0,0,0,.8)",
                    fontSize: 11,
                  }}>{item.value} {unit[index]}</span>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return null;
  };

  const CustomLegend = (props) => {
    const { payload } = props;

    return (
      <ul className="DashboardChart__legend">
        {payload.map((entry, index) => (
          <li
            className="DashboardChart__legend-item"
            key={`item-${index}`}
            style={{ color: entry.color }}
          >
            <span
              className="DashboardChart__legend-dot"
              style={{ backgroundColor: entry.color }}
            />
            <span style={{ lineHeight: "1em", fontSize: 12 }}>{labelFormatter(entry.value)}</span>
          </li>
        ))}
      </ul>

    );
  };

  const getBreakpoints = (width, large, medium, small, tiny) => {
    if (tiny && width < 480) return tiny;
    if (small && width < 768) return small;
    if (medium && width < 1024) return medium;
    return large;
  }
  const chartSize = {
    barSize: getBreakpoints(width, 4, 3, 2, 2),
    height: getBreakpoints(width, 230, 200, 180, 160),
  };

  const colors = ["#9A6FF8", "#33CACA"];

  return (
    <div className="TrackingChart">
      <ResponsiveContainer width="100%" height={chartSize.height}>
        <BarChart
          data={stats}
          margin={{
            top: 5,
            right: 0,
            left: -36,
            bottom: 0,
          }}
          barCategoryGap={2}
          barGap={2}
        >
          <XAxis
            dataKey="minutes_ago"
            name="Time"
            stroke="#555"
            style={{ fontSize: 10 }}
          />

          <YAxis
            yAxisId="left"
            orientation="left"
            stroke={colors[0]}
            style={{ fontSize: 10 }}
          />

          <YAxis
            yAxisId="right"
            orientation="right"
            stroke={colors[1]}
            style={{ fontSize: 10 }}
          />

          <Tooltip
            content={
              <CustomTooltip
                title="minutes ago"
                unit={["", "seconds"]}
              />
            }
            cursor={{ fill: "rgba(255,255,255,.05)" }}
          />

          <Legend
            content={CustomLegend}
          />

          <Bar
            dataKey="requests"
            yAxisId="left"
            fill={colors[0]}
            radius={2}
            barSize={3.5}
          />

          <Bar
            dataKey="avg_request_time"
            yAxisId="right"
            fill={colors[1]}
            radius={2}
            barSize={3.5}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

const TrackerSetup = (props) => {
  const { tracker, stats, statsIsLoaded, refreshTracker } = props;
  const [cookies] = useCookies(["token"]);
  const params = useParams();
  const warehouseId = params.warehouse_id;
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [verificationError, setVerificationError] = useState(null);
  const [verificationIsLoaded, setVerificationIsLoaded] = useState(true);
  const { createConfirmDialog } = useAppData();

  const proxyIp = "65.21.216.240";

  function handleVerify(e) {
    if (e) e.preventDefault();

    setVerificationIsLoaded(false);

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.verifySSGTM(warehouseId, tracker.id)
        .then(
          (result) => {
            setVerificationIsLoaded(true);
            setVerificationStatus(result);

            if (result.host && result.host.length > 0) {
              createConfirmDialog({
                type: 'info',
                title: 'Verify DNS records',
                message: <>{result.host}</>,
                onConfirm: () => {
                  refreshTracker();
                },
              });
            }
          },
          (error) => {
            setVerificationIsLoaded(true);
            setVerificationError(error);
          }
        );
    }
  }

  const VerifyButton = (props) => {
    const { data, isLoaded, error, onClick } = props;

    if (isLoaded === false) {
      return (
        <Button
          className="TrackerSetup__button TrackerSetup__button--loading"
        >
          <LoadingDots />
        </Button>
      );
    }

    if (data?.overall === "(done)") return "";

    if (error) {
      console.log('Verification error: ', error);
      return (
        <Button
          disabled={true}
          className="TrackerSetup__button TrackerSetup__button--error"
        >
          Error
        </Button>
      );
    }

    return (
      <Button
        onClick={onClick}
        className="TrackerSetup__button"
        iconRight="approve"
      >
        Verify DNS records
      </Button>
    );
  }

  const steps = [
    {
      title: "Add new tracker to Cogny",
      text: "Enter site and container information to create a new tracker",
      active: false,
      done: true,
    },
    {
      title: "Add DNS records",
      text: "Point " + tracker.host + " (A record) to " + proxyIp,
      active: tracker.status === "new",
      done: tracker.status === "configured" || tracker.status === "active",
      action: <VerifyButton
        data={verificationStatus}
        isLoaded={verificationIsLoaded}
        error={verificationError}
        onClick={handleVerify}
      />,
    },
    {
      title: "Deploy GTM servers",
      text: "Cogny automatically deploys your GTM server",
      active: tracker.status === "configured",
      done: tracker.status === "active",
      action: <div classname="TrackerSetup__working"><LoadingDots /></div>,
    },
    {
      title: "Configure Google Tag Manager",
      text: "Add " + tracker.host + " to your client-side GTM and configure your server-side GTM to start collecting data",
      active: tracker.status === "active",
      done: tracker.status === "active" && statsIsLoaded && stats.length > 0,
    },
  ];

  return (
    <div className="Tracker__setup TrackerSetup">
      <div className="TrackerSetup__header">Your tracker is almost done. Follow the instructions below to complete your setup.</div>
      {
        steps.map((step, index) => {
          return (
            <div
              key={index}
              className={
                "TrackerSetup__step"
                + (step.active ? " TrackerSetup__step--active" : "")
                + (step.done ? " TrackerSetup__step--done" : "")
                + (!step.active && !step.done ? " TrackerSetup__step--disabled" : "")
              }
            >
              <div className="TrackerSetup__content">
                <h3 className="TrackerSetup__title">
                  {step.done && <Icon className="TrackerSetup__check" icon="check" />}
                  {step.title}
                </h3>
                {!step.done && <div className="TrackerSetup__text">{step.text}</div>}
              </div>
              {step.active && step.action && <div className="TrackerSetup__action">{step.action}</div>}
            </div>
          );
        })
      }
    </div>
  );
}

const TrackerSummary = (props) => {
  const { stats } = props;

  const [activityRating, setActivityRating] = useState(null);
  const [requestTimesRating, setRequestTimesRating] = useState(null);
  const [processingTimeRating, setProcessingTimeRating] = useState(null);

  const rateActivity = (data) => {
    const totalMinutes = data.length;
    const activeMinutes = data.filter(entry => entry.requests > 0 || entry.bytes > 0).length;
    const activePercentage = (activeMinutes / totalMinutes) * 100;

    if (activePercentage <= 5) {
      return "Low";
    } else if (activePercentage <= 30) {
      return "Medium";
    } else {
      return "High";
    }
  }

  const rateRequestTimes = (data) => {
    const totalMinutes = data.length;
    const slowMinutes = data.filter(entry => entry.avg_request_time > 0.5).length;
    const slowPercentage = (slowMinutes / totalMinutes) * 100;

    if (slowPercentage <= 5) {
      return "Low";
    } else if (slowPercentage <= 30) {
      return "Medium";
    } else {
      return "High";
    }
  }

  const rateProcessingTime = (data) => {
    const totalMinutes = data.length;
    const slowMinutes = data.filter(entry => entry.processing_time > 0.5).length;
    const slowPercentage = (slowMinutes / totalMinutes) * 100;

    if (slowPercentage <= 5) {
      return "Low";
    } else if (slowPercentage <= 30) {
      return "Medium";
    } else {
      return "High";
    }
  }

  useEffect(() => {
    if (stats.length >= 30) {
      setActivityRating(rateActivity(stats));
      setRequestTimesRating(rateRequestTimes(stats));
      setProcessingTimeRating(rateProcessingTime(stats));
    }

    return () => {
      setActivityRating(null);
      setRequestTimesRating(null);
      setProcessingTimeRating(null);
    }
  }, [stats])

  return (
    <div className="Tracker__summary">
      <div className="Tracker__history">
        <h2 className="Tracker__title">Latest 30 minutes</h2>
        <TrackerChart stats={stats} />
      </div>
      <div className="Tracker__report">
        <h2 className="Tracker__title">Overview</h2>
        <div className="Tracker__rows">
          <div className="Tracker__row">
            <div className="Tracker__key">Recent activity</div>
            <div className="Tracker__result">
              {activityRating ?? "Not available"}
            </div>
          </div>
          <div className="Tracker__row">
            <div className="Tracker__key">Request times</div>
            <div className="Tracker__result" data-rating={requestTimesRating}>
              {requestTimesRating ?? "Not available"}
            </div>
          </div>
          <div className="Tracker__row">
            <div className="Tracker__key">Processing time</div>
            <div className="Tracker__result" data-rating={processingTimeRating}>
              {processingTimeRating ?? "Not available"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const TrackerEditorRule = (props) => {
  const { rule, onSave, onDelete } = props;
  const [validating, setValidating] = useState({ name: false, type: false, salt: false });
  const [modalState, setModalState] = useState({
    form: {
      data: rule,
      isLoaded: true,
      error: null,
    },
    open: !rule.name,
  });
  const updateModalState = (newState) => {
    setModalState(currentState => ({ ...currentState, ...newState }));
  }

  const modalForm = modalState.form.data;

  const updateModalForm = (newForm) => {
    updateModalState({
      form: {
        ...modalState.form,
        data: {
          ...modalState.form.data,
          ...newForm
        }
      }
    });
  }

  const nameValidation = /^[a-zA-Z0-9_]{3,}$/;

  const Validation = (props) => {
    const { className, rules, message, active } = props;

    if (!active) return;

    if (rules) {
      return <div className={"Validation " + className}>{message}</div>;
    }

    return "";
  };

  const validateForm = () => {
    const isNameValid = nameValidation.test(modalForm.name) && modalForm.name.length > 0;
    const isSaltValid = modalForm.salt.length > 0;
    const isTypeValid = modalForm.type.length > 0;

    return isNameValid && isSaltValid && isTypeValid;
  };

  const handleBlur = (e) => {
    // Don't trigger validation if the blur was triggered by a button click
    if (!e.relatedTarget || e.relatedTarget.tagName !== "BUTTON") {
      const { name } = e.target;
      setValidating(currentState => ({ ...currentState, [name]: true }));
    }
  }

  const handleSave = (e) => {
    e.preventDefault();

    setValidating({ name: true, type: true, salt: true });

    if (!validateForm()) {
      return;
    }

    onSave(modalForm);
    updateModalState({ ...modalState, open: false });
  }

  const knownNames = [
    { name: "cid", description: "Google Analytics" },
    { name: "FPID", description: "Google Analytics" },
    { name: "FPLC", description: "Google Analytics" },
    { name: "_ga", description: "Google Analytics" },
  ];

  const currentDescription = knownNames.find((item) => item.name === rule.name)?.description;

  return (
    <div className={"TrackerEditorRule" + (rule.name.length ? "" : " TrackerEditorRule--new")}>
      <div className="TrackerEditorRule__content">
        <div className="TrackerEditorRule__header">
          <span className="TrackerEditorRule__name">
            {rule.name.length ? rule.name : "Empty rule"}
          </span>
          {currentDescription &&
            <span className="TrackerEditorRule__description">
              ({currentDescription})
            </span>
          }
        </div>
        <div className="TrackerEditorRule__type">
          {rule.type}
        </div>
      </div>
      <div className="TrackerEditorRule__actions">
        <ModalDialog
          iconSide="left"
          icon="edit"
          label="Edit"
          size="small"
          className="TrackerEditorRule__toggle"
          title="Edit"
          modalClassName="TrackerEditorRuleEdit"
          modalTitle={rule.name ? "Edit rule" : "New rule"}
          state={{ modalState, updateModalState }}
          toggleSize="micro"
          toolbar={
            {
              primary: {
                ...modalState.primary,
                label: rule.name ? "Update rule" : "Add rule",
                onClick: (e) => {
                  handleSave(e);
                },
                icon: false,
              },
              secondary: {
                label: "Cancel",
                onClick: () => {
                  updateModalState({ ...modalState, open: false });
                  if (!rule.name) onDelete();
                },
              }
            }
          }
          onCancel={() => {
            if (!rule.name) onDelete();
            updateModalState({ ...modalState, open: false });
          }}
        >
          <div className="TrackerEditorRuleEdit__form">
            <div className="FieldRow">
              <Field
                label="Type"
                type="radiogroup"
                id="ruleType"
                name="type"
                options={[
                  {
                    value: "param",
                    label: "Param",
                    salt: generateSalt(),
                    description: "A value passed as a URL parameter",
                  },
                  {
                    value: "cookie",
                    label: "Cookie",
                    salt: generateSalt(),
                    description: "A value stored in a cookie",
                  },
                ]}
                value={modalForm.type}
                onChange={(e) => updateModalForm({ type: e.target.value })}
                validation={
                  <Validation active={validating.type} rules={!modalForm.type} message="You need to select a type" />
                }
              />
            </div>

            <div className="FieldRow">
              <Field
                type="text"
                id="ruleName"
                label="Name"
                name="name"
                placeholder="Enter a name"
                value={modalForm.name}
                onChange={(e) => updateModalForm({ name: e.target.value })}
                onBlur={handleBlur}
                validation={
                  <Validation active={validating.name} rules={!nameValidation.test(modalForm.name)} message="You need to enter a correct name" />
                }
              />
            </div>

            <div className="FieldRow">
              <Field
                type="text"
                id="ruleSalt"
                label="Salt"
                name="salt"
                placeholder="Enter a salt"
                value={modalForm.salt}
                onChange={(e) => updateModalForm({ salt: e.target.value })}
                onBlur={handleBlur}
                action={
                  <Button
                    iconLeft="settings"
                    label="Generate"
                    size="micro"
                    onClick={() => updateModalForm({ salt: generateSalt() })}
                  />
                }
                validation={
                  <Validation active={validating.salt} rules={!modalForm.salt} message="You need to enter a salt" />
                }
              />
            </div>
          </div>
        </ModalDialog>

        <Button
          iconLeft="trashcan"
          label="Discard"
          size="micro"
          theme="darkerred"
          onClick={onDelete}
        />
      </div>

    </div>
  );
}

const TrackerEditor = (props) => {
  const { state } = props;
  const { modalState, updateModalState } = state;

  const defaultRule = { action: "hash", name: "", type: false, salt: generateSalt() };

  const modalForm = modalState.form.data;

  const updateModalForm = (newForm) => {
    updateModalState({
      ...modalState,
      form: {
        ...modalState.form,
        data: {
          ...modalState.form.data,
          ...newForm
        }
      }
    });
  }

  // Function to update a specific rule in schema_data
  const handleRuleChange = (index, updatedRule) => {
    const updatedSchemaData = modalForm?.schema_data?.map((rule, i) =>
      i === index ? updatedRule : rule
    );
    updateModalForm({ schema_data: updatedSchemaData });
  };

  const handleDelete = (index) => {
    const updatedSchemaData = modalForm?.schema_data?.filter((rule, i) => i !== index);
    updateModalForm({ schema_data: updatedSchemaData });
  };

  const addStandardHashing = (e) => {
    const standardRules = [
      { name: "cid", type: "param", action: "hash", salt: generateSalt() },
      { name: "FPID", type: "cookie", action: "hash", salt: generateSalt() },
      { name: "FPLC", type: "cookie", action: "hash", salt: generateSalt() },
      { name: "_ga", type: "cookie", action: "hash", salt: generateSalt() },
    ];

    // check rules for duplicates before adding standardRules to array
    const rules = modalForm.schema_data;
    let newRules;
    if (rules && rules.length > 0) {
      newRules = [...rules];
      standardRules.forEach((rule) => {
        const found = newRules.some(r => r.name === rule.name);
        if (!found) newRules.push(rule);
      });
    } else {
      newRules = standardRules;
    }

    updateModalForm({ schema_data: newRules });
  };

  const handleAddNewRule = () => {
    //check if there are any rules in the schema_data array, or else add the defaultRule with updateModalForm
    if (!modalForm.schema_data) {
      updateModalForm({ schema_data: [defaultRule] });
    } else {
      updateModalForm({ schema_data: [...modalForm.schema_data, defaultRule] });
    }

    //open the rule editor for the new rule
  }

  const handleAddStandardRules = () => {
    addStandardHashing();
  }

  const handleSave = (e) => {
    e.preventDefault();
  };

  return (
    <Form className="TrackerEditor__form" onSubmit={handleSave}>
      <div className="FieldRow">
        <Field
          type="text"
          id="trackerHost"
          label="Host"
          name="Host"
          placeholder="Your tracker hostname"
          value={modalForm.host}
          disabled={true}
          description="Your tracker hostname can't be changed."
        />
      </div>

      <div className="FieldRow">
        <Field
          type="radiogroup"
          id="trackerCapacity"
          className="NewTrackerForm__capacity"
          name="Capacity"
          options={getPricingOptions()}
          onChange={(e) => updateModalForm({ size: e.target.value })}
          classNamePrefix="Radiogroup"
          description={<>Need more than 500m events? <a href="mailto:contact@cogny.com">Contact sales</a> for details.</>}
          value={modalForm.size}
        />
      </div>

      <div className="FieldRow">
        <Field
          type="radiogroup"
          id="trackerAnonymizeIp"
          className="NewTrackerForm__anonymizeIp"
          name="IP Filtering"
          options={[
            {
              value: "yes",
              label: "Enabled",
              description: <>IP addresses <strong>will not</strong> be passed on to Google</>
            },
            {
              value: "no",
              label: "Disabled",
              description: <>IP addresses <strong>will</strong> be passed on to Google</>
            },
          ]}
          onChange={(e) => updateModalForm({ anonymize_ip: e.target.value })}
          classNamePrefix="Radiogroup"
          description={<>Do you want to send user IP addresses to Google tag manager?</>}
          value={(modalForm.anonymize_ip && modalForm.anonymize_ip !== "no") ? "yes" : "no"}
        />
      </div>

      <div className="TrackerEditor__section">
        <h3 className="TrackerEditor__title">Hashing rules</h3>
        <div className="TrackerEditor__note">
          Add rules to hash user data before passing it on to GTM.
        </div>
        <div className="TrackerEditor__rulebox">
          {modalForm?.schema_data?.length > 0 &&
            <div className="TrackerEditor__rules">
              {modalForm?.schema_data?.map((rule, index) => (
                <TrackerEditorRule
                  key={index}
                  rule={rule}
                  onSave={(updatedRule) => handleRuleChange(index, updatedRule)}
                  onDelete={() => handleDelete(index)}
                />
              ))}
            </div>
          }

          <div className="TrackerEditor__addRules">
            <Button
              className="TrackerEditor__addButton"
              iconLeft="plus"
              label="Add new rule"
              onClick={handleAddNewRule}
              size="micro"
            />
            <Button
              className="TrackerEditor__addButton TrackerEditor__addButton--secondary"
              iconLeft="plus"
              label="Add standard rules"
              onClick={handleAddStandardRules}
              size="micro"
            />
          </div>
        </div>
      </div>
    </Form>
  );
}

const Tracker = (props) => {
  const { id, item, onDelete } = props;
  const [cookies] = useCookies(["token"]);
  const { createConfirmDialog } = useAppData();

  const params = useParams();
  const warehouseId = params.warehouse_id;

  const [tracker, setTracker] = useState(item);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [, setDeleteError] = useState(null);
  const [deleteIsLoaded, setDeleteIsLoaded] = useState(true);

  const [, setUpdateError] = useState(null);
  const [updateIsLoaded, setUpdateIsLoaded] = useState(true);

  const [stats, setStats] = useState([]);
  const [statsError, setStatsError] = useState(null);
  const [statsIsLoaded, setStatsIsLoaded] = useState(false);

  const mounted = useRef(true);

  const toolbarExtras = <>
    <Button
      variant="danger"
      iconLeft="trashcan"
      label="Delete tracker"
      onClick={handleDelete}
    />
  </>;

  const defaultModalState = {
    form: {
      data: tracker,
      isLoaded: true,
      error: null,
    },
    open: false,
    toolbarExtras,
  }

  const [modalState, setModalState] = useState(defaultModalState);

  const updateModalState = (newState) => {
    setModalState(currentState => ({ ...currentState, ...newState }));
  }

  const modalForm = modalState.form.data;

  const updateModalForm = (newForm) => {
    updateModalState({
      form: {
        ...modalState.form,
        data: {
          ...modalState.form.data,
          ...newForm
        }
      }
    });
  }

  const resetModalState = () => setModalState(defaultModalState);

  function handleDelete(e) {
    e.preventDefault();
    const currentId = tracker.id;

    createConfirmDialog({
      type: 'delete',
      title: 'Delete tracker',
      message: (
        <>
          <p>Are you sure you want to delete {tracker.host}?</p>
        </>
      ),
      onConfirm: () => {
        setDeleteIsLoaded(false);

        const api = new CognyAPI(cookies.token);
        api.deleteSSGTM(warehouseId, currentId)
          .then(
            (result) => {
              setDeleteIsLoaded(true);
              onDelete();
              updateModalState({ open: false });

              createConfirmDialog({
                type: 'info',
                title: 'Tracker deleted',
                message: (
                  <>
                    <p>{tracker.host} has been deleted.</p>
                  </>
                ),
                onConfirm: () => { },
              });
            },
            (error) => {
              setDeleteIsLoaded(true);
              setDeleteError(error);

              createConfirmDialog({
                type: 'info',
                title: 'Delete failed',
                message: (
                  <>
                    <p>Failed to delete tracker.</p>
                    <p>{error.message}</p>
                  </>
                ),
              });
            }
          );
      },
    });
  }

  function handleUpdate(e) {
    e.preventDefault();

    setUpdateIsLoaded(false);

    let trackerData = modalForm;

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);

      if (trackerData.anonymize_ip === "yes") {
        trackerData.anonymize_ip = true;
      } else {
        trackerData.anonymize_ip = false;
      }

      if (trackerData.schema_data === "") {
        trackerData.schema_data = [];
      }

      api.updateSSGTM(warehouseId, trackerData)
        .then(
          (result) => {
            setUpdateIsLoaded(true);
            setTracker(currentState => ({
              ...currentState,
              ...result
            }));
          },
          (error) => {
            setUpdateIsLoaded(true);
            console.log(error);
            setUpdateError(error);

            createConfirmDialog({
              type: 'info',
              title: 'Update failed',
              message: (
                <>
                  <p>Failed to update tracker settings.</p>
                  <p>{error.message}</p>
                </>
              ),
            });
          }
        );
    }
  }

  const getTracker = () => {
    setIsLoaded(false);
    const api = new CognyAPI(cookies.token);
    api.getSSGTM(warehouseId, id)
      .then(
        (result) => {
          if (!mounted.current) return;
          if (result.schema_data === "" || result.schema_data === null || typeof result.schema_data === "undefined") {
            result.schema_data = [];
          }

          if (result.anonymize_ip) {
            result.anonymize_ip = 'yes';
          } else {
            result.anonymize_ip = 'no';
          }
          setTracker(result);
          if (!modalForm.isLoaded) updateModalForm(result);
          setIsLoaded(true);
        },
        (error) => {
          if (!mounted.current) return;
          setError(error);
          setIsLoaded(true);
        }
      );
  }

  useEffect(() => {
    if (tracker.length > 0) return false;

    mounted.current = true;
    if (cookies.token) {
      getTracker();
    }
    return () => {
      mounted.current = false;
      setIsLoaded(false);
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function fetchStats() {
      function timestampToMinutesAgo(unixTime) {
        // Convert time to number of minutes ago
        let time = new Date();
        time.setSeconds(0, 0);
        let deltaMinutes = (time.getTime() - unixTime * 1000) / 1000 / 60;
        return deltaMinutes.toFixed(0) + ""
      }

      function round3(num) {
        // Round number to 3 decimals
        return num.toFixed(3);
      }

      if (cookies.token && tracker.id && tracker.status === "active") {
        const api = new CognyAPI(cookies.token);
        api.getSSGTMStats(warehouseId, tracker.id)
          .then(
            (result) => {
              result = result.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1).map((item) => {
                item.avg_request_time = round3(item.processing_time / item.requests);
                return item;
              });

              let res = {};
              for (let i = 0; i < result.length; i++) {
                let minutesAgo = timestampToMinutesAgo(result[i].timestamp);
                if (minutesAgo in res) {
                  res[minutesAgo].bytes += result[i].bytes;
                  res[minutesAgo].requests += result[i].requests;
                  res[minutesAgo].processing_time += result[i].processing_time;
                  res[minutesAgo].avg_request_time = round3(res[minutesAgo].processing_time / res[minutesAgo].requests);
                } else {
                  res[minutesAgo] = result[i];
                  res[minutesAgo].minutes_ago = minutesAgo;
                  delete res[minutesAgo].path;
                }
              }

              let resultLast30Minutes = [];
              for (let j = 30; j > -1; j--) {
                if (j in res) {
                  resultLast30Minutes.push(res[j]);
                } else {
                  resultLast30Minutes.push({
                    "minutes_ago": j,
                    "requests": 0,
                    "bytes": 0,
                    "processing_time": 0,
                    "avg_request_time": 0,
                  });
                }
              }

              setStats(resultLast30Minutes);
              setStatsIsLoaded(true);
            },
            (error) => {
              setStatsError(error);
              setStatsIsLoaded(true);
            }
          );
      }
    }

    fetchStats();
    const interval = setInterval(fetchStats, 10000);

    return () => {
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.token, warehouseId, tracker.id, tracker.status]);

  const trackerIsDone = tracker.status === "active";

  if (error) {
    console.log(error);
    return <>Error: {JSON.stringify(error)}</>;
  }

  return (
    <div
      className={
        "Tracker"
        + (!updateIsLoaded ? " Tracker--loading" : "")
      }
    >
      <div className="Tracker__header">
        <h2 className="Tracker__domain">{tracker.host}</h2>
        <div className="Tracker__actions">
          {debug &&
            <Dropdown
              label="Tracker debug"
              iconLeft="bug"
              iconRight="chevronDown"
              size="large"
              className="Tracker__toggle"
              dropdownClassName="Tracker__debug"
              title="Open debug"
              items={[
                {
                  id: "debugHeader",
                  label: "Debug info",
                  type: "header",
                  icon: "bug",
                },
                {
                  id: "debugInfo",
                  label: "Raw tracker data",
                  type: "text",
                  text: <pre>{JSON.stringify(tracker, null, 2)}</pre>,
                }
              ]}
            />
          }
          {trackerIsDone ?
            <ModalDialog
              iconSide="left"
              icon="edit"
              label="Edit tracker..."
              size="small"
              className="Tracker__toggle"
              title="Edit tracker..."
              modalClassName="TrackerEditor"
              modalTitle="Edit tracker"
              state={{ modalState, updateModalState }}
              toolbar={
                {
                  primary: {
                    ...modalState.primary,
                    label: "Save",
                    onClick: (e) => {
                      handleUpdate(e);
                      updateModalState({ ...modalState, open: false });
                    }
                  },
                  secondary: {
                    label: "Cancel",
                    onClick: resetModalState
                  }
                }
              }
              isLoaded={deleteIsLoaded}
              onCancel={resetModalState}
            >
              <TrackerEditor
                tracker={tracker}
                state={{ modalState, updateModalState }}
              />
            </ModalDialog>
            :
            <Button
              size="medium"
              theme="darkerred"
              iconLeft="trashcan"
              label="Delete tracker"
              onClick={handleDelete}
            />
          }
        </div>
      </div>
      <div className="Tracker__content">
        {
          !isLoaded ?
            <div className="Tracker__loading">
              <LoadingDots />
            </div>
            :
            trackerIsDone ?
              <TrackerSummary
                stats={stats}
                statsError={statsError}
                statsIsLoaded={statsIsLoaded}
              />
              :
              <TrackerSetup
                tracker={tracker}
                stats={stats}
                statsIsLoaded={statsIsLoaded}
                refreshTracker={getTracker}
              />
        }
      </div>
      {!updateIsLoaded && <div className="Tracker__loading"><LoadingDots /></div>}
    </div>
  );
}

const GoogleLogin = (props) => {
  const { onLoginSuccess, onLogout, isLoggedIn, user } = props;
  const login = useGoogleLogin({
    onSuccess: onLoginSuccess,
    onError: () => console.log('Login Failed'),
    scope: googleConfig.apiScopes,
    clientId: googleConfig.googleClientId,
  });

  return (
    <div
      className={
        "GoogleLogin"
        + (isLoggedIn ? " GoogleLogin--loggedin" : "")
      }
    >
      {isLoggedIn ? (
        <>
          <div
            className="GoogleLogin__content"
          >
            <span
              className="GoogleLogin__meta"
            >
              Logged in as
            </span>
            <div className="GoogleLogin__user">
              <img
                src={user.picture}
                alt="Avatar"
                className="GoogleLogin__avatar"
              />
              <span
                className="GoogleLogin__email"
              >
                {user.email}
              </span>
            </div>
          </div>
          <div className="GoogleLogin__action">
            <Button
              className="GoogleLogin__button GoogleLogin__button--signout"
              icon="arrowRight"
              iconSide="right"
              onClick={onLogout}
            >
              Sign out
            </Button>
          </div>
        </>
      ) : (
        <>
          <div
            className="GoogleLogin__content"
          >
            <span
              className="GoogleLogin__text"
            >
              Sign in with your Google Tag Manager admin account
            </span>
          </div>
          <div className="GoogleLogin__action">
            <Button
              className="GoogleLogin__button GoogleLogin__button--signin"
              icon="arrowRight"
              iconSide="right"
              onClick={() => login()}
            >
              Sign in with Google
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const NewTrackerForm = (props) => {
  const { onComplete } = props;
  const { updateModalState } = props.state;

  const [form, setForm] = useState({});
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);

  const updateForm = (newForm) => {
    setForm(prevForm => ({ ...prevForm, ...newForm }));
  }

  const [, setValidation] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});

  const params = useParams();
  const warehouseId = params.warehouse_id;
  const [cookies] = useCookies(["token", "project"]);

  const nameValidation = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][a-zA-Z0-9-]*[A-Za-z0-9])$/;
  const gtmCodeValidation = /^.+$/;
  const capacityValidation = /^(micro|mini|small|medium|large)$/;

  const [accessToken, setAccessToken] = useState("");
  const [gtmAccounts, setGtmAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedContainer, setSelectedContainer] = useState("");
  const [, setLoading] = useState(false);
  const [containers, setContainers] = useState([]);
  const [newContainerName, setNewContainerName] = useState('');
  const [domain, setDomain] = useState("");
  const [setupType, setSetupType] = useState("");

  const { createConfirmDialog } = useAppData();

  const onLoginSuccess = useCallback((response) => {
    setAccessToken(response.access_token);
    setIsLoggedIn(true);
    // Fetch user info
    axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: {
        Authorization: `Bearer ${response.access_token}`
      }
    }).then(res => {
      setUser(res.data);
    }).catch(err => {
      console.log('Failed to fetch user info:', err);
    });
  }, []);

  const onLogout = () => {
    setIsLoggedIn(false);
    setUser({});
    setAccessToken("");
  };

  useEffect(() => {
    const fetchGtmAccounts = async () => {
      try {
        const response = await axios.get('https://www.googleapis.com/tagmanager/v2/accounts', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setGtmAccounts(response.data.account || []);
      } catch (error) {
        console.error('Failed to list GTM accounts:', error);
      }
    };

    if (accessToken) {
      fetchGtmAccounts();
    }
  }, [accessToken]);

  useEffect(() => {
    if (selectedAccount) {
      fetchContainers();
      setSelectedContainer('');
    }
  }, [selectedAccount, accessToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchContainers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://www.googleapis.com/tagmanager/v2/accounts/${selectedAccount}/containers`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
      );
      const serverContainers = response.data.container.filter(container => container.usageContext.includes('server'));
      setContainers(serverContainers);

      if (serverContainers.length === 1) {
        setSelectedContainer(serverContainers[0].containerId);
      } else if (serverContainers.length > 0) {
        setSelectedContainer("");
      }

      setLoading(false);
    } catch (error) {
      console.error('Failed to list containers:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedContainer) {
      fetchAndGenerateConfig(selectedAccount, selectedContainer);
    }
  }, [selectedContainer, accessToken, selectedAccount]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAndGenerateConfig = async (selectedAccount, containerId) => {
    if (!containerId || !accessToken || !selectedAccount) return console.error('Missing required parameters');
    setLoading(true);
    try {
      const containerDetailsUrl = `https://www.googleapis.com/tagmanager/v2/accounts/${selectedAccount}/containers/${containerId}`;
      const containerDetailsResponse = await axios.get(containerDetailsUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      const publicId = containerDetailsResponse.data.publicId;
      const environmentDetailsUrl = `${containerDetailsUrl}/environments/1`;
      const environmentsResponse = await axios.get(environmentDetailsUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      const authorizationCode = environmentsResponse.data.authorizationCode;

      // Fetch taggingServerUrls
      const taggingServerUrls = containerDetailsResponse.data.taggingServerUrls;
      // console.log('Tagging Server URLs:', taggingServerUrls);

      if (taggingServerUrls && taggingServerUrls.length > 0) {
        const url = new URL(taggingServerUrls[0]);
        const domainWithoutProtocol = url.hostname;
        setDomain(domainWithoutProtocol);
        setForm(prevForm => ({ ...prevForm, host: domainWithoutProtocol }));
      }

      const configString = `id=${publicId}&env=1&auth=${authorizationCode}`;
      const configCode = btoa(configString);
      return configCode;

    } catch (error) {
      console.error('Failed to fetch container or environment details:', error);
    } finally {
      setLoading(false);
    }
  };

  const createContainer = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `https://www.googleapis.com/tagmanager/v2/accounts/${selectedAccount}/containers`, {
        name: newContainerName,
        usageContext: ['server'],
      }, {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
      );
      const newContainer = response.data;
      return newContainer.containerId;
    } catch (error) {
      console.error('Failed to create container:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = (form) => {
    return form.host && form.gtm_code && form.size &&
      nameValidation.test(form.host) &&
      gtmCodeValidation.test(form.gtm_code) &&
      capacityValidation.test(form.size);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newForm = { ...form };

    if (setupType === 'new') {
      const newContainerId = await createContainer();
      const newConfigCode = await fetchAndGenerateConfig(selectedAccount, newContainerId);
      newForm = { ...form, gtm_code: newConfigCode };
    } else if (setupType === 'existing') {
      const existingConfigCode = await fetchAndGenerateConfig(selectedAccount, selectedContainer);
      newForm = { ...form, gtm_code: existingConfigCode };
    }

    setForm(newForm);

    const validate = validateForm(newForm);

    if (!validate) {
      setValidation(false);
      setError({ message: 'The information you have entered is not valid' });
      return;
    }
    setValidation(true);

    setIsLoaded(false);

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);

      if (window.dataLayer) {
        window.dataLayer.push({ event: "add_ssgtm", token: cookies.token });
      }
      api.addSSGTM(warehouseId, newForm)
        .then(
          (result) => {
            setIsLoaded(true);
            setForm(prevForm => ({
              ...prevForm,
              ...result
            }));

            createConfirmDialog({
              type: 'info',
              title: 'Tracker added',
              message: (
                <>
                  <p>Your new tracker {result.host} has been added.</p>
                </>
              ),
              onConfirm: () => {
                updateModalState({ open: false });
                onComplete();
              },
            });
          },
          (error) => {
            setIsLoaded(true);
            console.log(error);
            setError(error);
          }
        )
    }
  }

  const handleDomainChange = (e) => {
    const newDomain = e.target.value;
    setDomain(newDomain);
    setForm(prevForm => ({ ...prevForm, host: `${newDomain}` }));
    setNewContainerName(`${newDomain} - SST`);
  };

  const Validation = (props) => {
    const { id } = props;
    let message = "";
    if (id === "host") {
      if (!nameValidation.test(form.host)) {
        message = "Host must be a valid domain name.";
      }
    }
    if (id === "gtm_code") {
      if (typeof form.gtm_code === "undefined" || !gtmCodeValidation.test(form.gtm_code)) {
        message = "GTM Container code must be set.";
      }
    }
    if (id === "capacity") {
      if (!capacityValidation.test(form.size)) {
        message = "Capacity must be set.";
      }
    }
    return message === "" ? "" : <div className="Validation">{message}</div>;
  };

  useEffect(() => {
    if (updateModalState) {
      const primary = {
        label: "Add new tracker",
        onClick: handleSubmit,
      }
      updateModalState({ primary })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  useEffect(() => {
    if (updateModalState) {
      const formData = {
        data: form,
        isLoaded: isLoaded,
        error: error,
      };
      updateModalState({ form: formData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isLoaded, error])

  return (
    <>
      <GoogleOAuthProvider clientId={googleConfig.googleClientId}>
        <div className="NewTrackerForm">
          <Form className="NewTrackerForm__form" onSubmit={handleSubmit}>
            <div className="FieldRow">
              <Field
                type="radiogroup"
                id="setupType"
                name="Setup type"
                options={[
                  {
                    value: "new",
                    label: "New container",
                    description: "We help you set up a new GTM server-side container.",
                  },
                  {
                    value: "existing",
                    label: "Existing container",
                    description: "You pick an existing GTM server-side container from your account.",
                  },
                  {
                    value: "manual",
                    label: "Manual",
                    description: "You provide the GTM server-side container code that you have already configured.",
                  },
                ]}
                value={setupType}
                onChange={(e) => {
                  setSetupType(e.target.value);
                }}
                placeholder="Select setup type"
                description={<>To add a new tracker we need to have a GTM server-side container. If you already have your config code, enter it manually. Otherwise, we will help you set it up.</>}
                validation={<Validation id="setupType" />}
              />
            </div>
            {setupType && (
              <>
                {(setupType === 'new' || setupType === 'existing') && !isLoggedIn && (
                  <GoogleLogin onLoginSuccess={onLoginSuccess} onLogout={onLogout} isLoggedIn={isLoggedIn} user={user} />
                )}
                {(isLoggedIn || setupType === 'manual') && (
                  <>
                    {setupType === 'new' && (
                      <>
                        <GoogleLogin onLoginSuccess={onLoginSuccess} onLogout={onLogout} isLoggedIn={isLoggedIn} user={user} />
                        <div className="FieldRow">
                          <Field
                            type="select"
                            id="accountSelect"
                            name="Account"
                            classNamePrefix="Select"
                            options={gtmAccounts.map(account => ({
                              value: account.accountId,
                              label: account.name
                            }))}
                            onChange={(selectedOption) => setSelectedAccount(selectedOption.value)}
                            placeholder="Select an account"
                            isSearchable={true}
                          />
                        </div>
                        <div className="FieldRow">
                          <Field
                            type="text"
                            id="trackerDomain"
                            className="NewTrackerForm__domain"
                            name="Subdomain for server-side GTM"
                            value={domain}
                            onChange={handleDomainChange}
                            placeholder="gtm.yourdomain.com"
                          />
                        </div>
                      </>
                    )}
                    {setupType === 'existing' && (
                      <>
                        <GoogleLogin onLoginSuccess={onLoginSuccess} onLogout={onLogout} isLoggedIn={isLoggedIn} user={user} />
                        <div className="FieldRow">
                          <Field
                            type="select"
                            id="accountSelect"
                            name="Account"
                            classNamePrefix="Select"
                            options={gtmAccounts.map(account => ({
                              value: account.accountId,
                              label: account.name
                            }))}
                            onChange={(selectedOption) => setSelectedAccount(selectedOption.value)}
                            placeholder="Select an account"
                            isSearchable={true}
                          />
                        </div>
                        <div className="FieldRow">
                          <Field
                            type="select"
                            id="containerSelect"
                            name="Available server containers"
                            classNamePrefix="Select"
                            options={containers.map(container => ({ value: container.containerId, label: container.name }))}
                            onChange={(selectedOption) => setSelectedContainer(selectedOption.value)}
                            placeholder="Select a container"
                            isSearchable={true}
                          />
                        </div>
                        <div className="FieldRow">
                          <Field
                            type="text"
                            id="trackerDomain"
                            className="NewTrackerForm__domain"
                            name="Subdomain for server-side GTM"
                            value={domain}
                            onChange={handleDomainChange}
                            placeholder="gtm.yourdomain.com"
                          />
                        </div>
                      </>
                    )}
                    {setupType === 'manual' && (
                      <>
                        <div className="FieldRow">
                          <Field
                            type="text"
                            id="trackerHost"
                            className="NewTrackerForm__host"
                            name="Host"
                            value={form.host || ''}
                            onChange={(e) => updateForm({ host: e.target.value })}
                            validation={<Validation id="host" />}
                            placeholder="gtm.yourdomain.com"
                          />
                        </div>
                        <div className="FieldRow">
                          <Field
                            type="text"
                            id="trackerGtmCode"
                            className="NewTrackerForm__gtm_code"
                            name="Container configuration"
                            value={form.gtm_code ? form.gtm_code : ''}
                            onChange={(e) => updateForm({ gtm_code: e.target.value })}
                            validation={<Validation id="gtm_code" />}
                            placeholder="aWXXXXYYYYYZZZZZZZZZZ.."
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {setupType && (
              <>
                <div className="FieldRow">
                  <Field
                    type="radiogroup"
                    id="trackerCapacity"
                    className="NewTrackerForm__capacity"
                    name="Capacity"
                    options={getPricingOptions()}
                    onChange={(e) => updateForm({ size: e.target.value })}
                    value={form.size}
                    classNamePrefix="Radiogroup"
                    validation={<Validation id="capacity" />}
                    placeholder="Select capacity"
                    description={<>Need more than 500m events? <a href="mailto:contact@cogny.com">Contact sales</a> for details.</>}
                  />
                </div>
              </>
            )}
            <SubmitStatus isLoaded={isLoaded} error={error} parentClass="NewTrackerForm" successMessage="" />
          </Form>
        </div>
      </GoogleOAuthProvider>
    </>
  );
}

const NewTracker = (props) => {
  const { add, toggleSize, toggleLabel, onComplete } = props;

  const [modalState, setModalState] = useState({
    open: add ? true : false,
    sent: false,
    primary: {
      label: "",
      onClick: () => { }
    }
  });

  const updateModalState = (newState) => {
    setModalState(currentState => ({ ...currentState, ...newState }));
  }

  return (
    <ModalDialog
      iconSide="left"
      icon="plus"
      label={toggleLabel}
      size="small"
      className="Tracking__new"
      title="New tracker"
      modalClassName="NewTracker"
      modalTitle={"Add new server-side tracker"}
      state={{ modalState, updateModalState }}
      toggleSize={toggleSize}
      toolbar={
        {
          primary: {
            ...modalState.primary,
          },
          secondary: {
            label: "Cancel",
            onClick: () => { updateModalState({ ...modalState, open: false }) }
          }
        }
      }
    >
      <NewTrackerForm
        state={{ modalState, updateModalState }}
        onComplete={onComplete}
      />
    </ModalDialog>
  );
}

function Tracking(props) {
  const { add } = props;
  const [cookies] = useCookies(["token"]);

  const params = useParams();
  const warehouseId = params.warehouse_id;

  const [trackers, setTrackers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const mounted = useRef(true);

  const deleteTracker = (id) => {
    setTrackers(prevState => prevState.filter(item => item.id !== id));
  }

  const getTrackers = () => {
    setIsLoaded(false);
    const api = new CognyAPI(cookies.token);
    api.getSSGTMs(warehouseId)
      .then(
        (result) => {
          if (!mounted.current) return;
          setTrackers(result);
          setIsLoaded(true);
        },
        (error) => {
          if (!mounted.current) return;
          setIsLoaded(true);
          setError(error);
        }
      );
  }

  useEffect(() => {
    mounted.current = true;
    if (cookies.token) {
      getTrackers();
    }
    return () => {
      mounted.current = false;
      setIsLoaded(false);
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    console.log(error);
    return <ErrorBox error={error} />;
  }

  if (!isLoaded) return <LoadingDots />;

  const bigMode = trackers.length === 0;

  return (
    <div className="Tracking">
      <PageHeader
        title="Server-side tracking"
        description="Enhance your website with our GDPR-compliant server-side tracking. This feature anonymizes and filters user data, ensuring compliance while maintaining precision in your data analytics."
        button={
          <NewTracker
            add={add}
            toggleSize={bigMode ? "large" : null}
            toggleLabel={bigMode ? "Add new tracker to continue" : "New tracker"}
            onComplete={() => {
              getTrackers();
            }}
          />
        }
        big={bigMode}
      />
      {!bigMode &&
        <div className="Tracking__items">
          {
            trackers.map(item => {
              return (
                <Tracker
                  item={item}
                  id={item.id}
                  key={item.id}
                  onDelete={() => deleteTracker(item.id)}
                />
              );
            })
          }
        </div>
      }
    </div>
  );
}

export default Tracking;
