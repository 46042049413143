import './style.scss';
import Icon from 'components/atoms/Icon';
import { Link } from 'react-router-dom';

function Button(props) {
  const {
    icon,
    iconSide = "left",
    iconLeft,
    iconRight,
    type = "button",
    href = "#",
    className = "",
    onClick,
    label,
    children,
    disabled,
    variant = "default",
    title = "",
    target,
    rel,
    noLabel
  } = props;
  const text = label || children;
  const themes = ["dark", "darker", "darkerred"];
  const theme = props.theme && themes.includes(props.theme) ? props.theme : "default";

  const sizes = ["micro", "small", "medium", "large", "default"];
  const size = sizes.includes(props.size) ? props.size : "default";

  let buttonClass = "Button ";
  buttonClass = buttonClass + className;
  buttonClass = buttonClass + (noLabel ? " Button--noLabel" : "");
  buttonClass = buttonClass + (theme !== "default" ? " Button--" + theme : "");
  buttonClass = buttonClass + (size !== "default" ? " Button--" + size : "");

  const ButtonLabel = (props) => {
    const { icon, iconSide, iconLeft, iconRight } = props.icon;

    return (
      <>
        {icon && iconSide === 'left' && (<Icon icon={icon} />)}
        {iconLeft && (<Icon icon={iconLeft} />)}
        <span className="Button__label">{!noLabel && text}</span>
        {icon && iconSide === 'right' && (<Icon icon={icon} />)}
        {iconRight && (<Icon icon={iconRight} />)}
      </>
    );
  }

  if (type === 'htmllink') {
    return (
      <a
        href={href}
        className={buttonClass}
        onClick={onClick}
        rel={rel}
        target={target}
      >
        <ButtonLabel icon={{ icon, iconSide, iconLeft, iconRight }} />
      </a>
    );
  }

  if (type === 'link') {
    return (
      <Link
        to={href}
        className={buttonClass}
        onClick={onClick}
      >
        <ButtonLabel icon={{ icon, iconSide, iconLeft, iconRight }} />
      </Link>
    );
  }

  buttonClass = buttonClass + (variant === "danger" ? " Button--danger" : "");

  return (
    <button
      className={buttonClass}
      onClick={onClick}
      type={type}
      disabled={disabled}
      title={title}
    >
      <ButtonLabel icon={{ icon, iconSide, iconLeft, iconRight }} />
    </button>
  );
}

export default Button;
