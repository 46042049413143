import "./style.scss";
import Button from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import React, { useCallback, useEffect, useState } from "react";

const dropdownEvent = new Event("dropdownCloseAll");

function Dropdown(props) {
  const {
    className = "",
    dropdownClassName = "",
    label,
    title,
    iconLeft,
    iconRight,
    theme,
    button,
    items
  } = props;
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!open) {
      document.dispatchEvent(dropdownEvent);
    }
    setOpen(!open);
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setOpen(false)
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    document.addEventListener("dropdownCloseAll", () => setOpen(false), false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
      document.removeEventListener("dropdownCloseAll", () => setOpen(false), false);
    };
  }, [escFunction]);

  return (
    <div className="Dropdown">
      {label &&
        <Button
          className={"Dropdown__toggle " + className}
          onClick={handleClick}
          onMouseDown={(e) => e.stopPropagation()}
          title={title}
          iconLeft={iconLeft}
          iconRight={iconRight}
          theme={theme}
        >
          {label}
        </Button>
      }

      {!label && button &&
        <button
          className={"Dropdown__toggle " + className}
          onClick={handleClick}
          onMouseDown={(e) => e.stopPropagation()}
          title={title}
        >
          {button}
        </button>
      }

      {open &&
        <div
          className={"Dropdown__menu " + dropdownClassName}
        >
          <div
            className="Dropdown__items"
          >
            {items.map((item, index) => {
              const id = item.id || index;

              if (item.type === "separator") {
                return (
                  <div
                    key={id}
                    className="Dropdown__item Dropdown__item--separator"
                  />
                );
              }

              if (item.type === "header") {
                return (
                  <div
                    key={id}
                    className={
                      "Dropdown__item"
                      + (item.icon ? " Dropdown__item--header-with-icon" : " Dropdown__item--header")
                    }
                  >
                    {item.icon && <Icon icon={item.icon} />}
                    {item.label}
                  </div>
                );
              }

              if (item.type === "radio") {
                const itemIsLoaded = item.value !== undefined && item.isLoaded;
                return (
                  <React.Fragment
                    key={id}
                  >
                    <div
                      className={
                        "Dropdown__item Dropdown__item--header"
                      }
                    >
                      {item.label}
                    </div>

                    <>
                      {item.options.map((option, index) => {
                        return (
                          <div
                            key={index}
                            className={
                              "Dropdown__item Dropdown__item--radio"
                              + (!itemIsLoaded ? " Dropdown__item--loading" : "")
                            }
                          >
                            <input
                              className="Dropdown__input"
                              key={option.value}
                              type="radio"
                              name={item.id}
                              id={option.value}
                              value={option.value}
                              onChange={item.onChange}
                              checked={option.value === item.value}
                            />
                            <label
                              key={index}
                              className="Dropdown__input-label Dropdown__trigger"
                              htmlFor={option.value}
                            >
                              <span
                                className="Dropdown__icon Dropdown__icon--radio"
                              >
                                <Icon
                                  icon="check"
                                />
                              </span>

                              <span
                                className="Dropdown__label"
                              >
                                {option.label}
                              </span>
                            </label>
                          </div>
                        );
                      })}
                    </>
                  </React.Fragment>
                );
              }

              if (item.type === "action") {
                const itemIsLoaded = typeof item.isLoaded === "undefined" || item.isLoaded;

                return (
                  <div
                    key={id}
                    className={
                      "Dropdown__item Dropdown__item--action"
                      + (item.color ? " Dropdown__item--" + item.color : "")
                      + (!itemIsLoaded ? " Dropdown__item--loading" : "")
                    }
                  >
                    <button
                      className="Dropdown__trigger"
                      onClick={item.onClick}
                    >
                      {item.icon &&
                        <span
                          className="Dropdown__icon"
                        >
                          <Icon
                            icon={item.icon}
                          />
                        </span>
                      }

                      <span
                        className="Dropdown__label"
                      >
                        {item.label}
                      </span>
                    </button>
                  </div>
                );
              }

              if (item.type === "dialog") {
                return (
                  <div className="Dropdown__item Dropdown__item--dialog">
                    {item.dialog}
                  </div>
                );
              }

              if (item.type === "text") {
                return (
                  <div
                    key={id}
                    className="Dropdown__item Dropdown__item--text"
                  >
                    {item.text}
                  </div>
                );
              }

              return <></>;
            })}
          </div>
        </div>
      }
    </div>
  )
};

export default Dropdown;